import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { Divider, Flex, HStack, Link, Spinner, Text } from "@chakra-ui/react";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { show } from "redux-modal";
import { api } from "src/api";
import {
  BaseContentFields,
  BaseProductFields,
  BaseSourceFields,
} from "src/api/fragments";
import {
  ContentStatusEnum,
  MutationUpdateCharacterArgs,
  Query,
} from "src/api/generated/types";
import { ActionSheet } from "src/components/ActionSheet";
import { Button } from "src/components/Button";
import { Textarea } from "src/components/Form";
import { Info } from "src/components/Info";
import StatusTag from "src/components/StatusTag";
import { Touchable } from "src/components/Touchable";
import { useMyToast, useTheme } from "src/hooks";
import { getActiveCharacter } from "src/redux/reducers/activeCharacter";
import { colors } from "src/theme";

export const CharacterDetailSidebar = () => {
  const theme = useTheme();
  const character = useSelector(getActiveCharacter);
  const toast = useMyToast();
  const dispatch = useDispatch();
  const { characterId } = useParams();
  const [search] = useSearchParams();
  const contentId = search.get("contentId");
  const [activeTab, setActiveTab] = useState("content");

  const [prompt, setPrompt] = useState(character?.personalityPrompt || "");
  const [updateCharacter] = useMutation(api.characters.update);
  const [embedAllCharacterContent] = useMutation(
    api.content.embedAllCharacterContent
  );

  const { data: sourceData, error: sourceError } = useQuery<
    Pick<Query, "getCharacterSources">
  >(api.characters.sources, {
    variables: {
      characterId: character?.id || "",
    },
    skip: !character?.id,
  });

  const { data: contentSummaryData } = useQuery<
    Pick<Query, "getCharacterContentSummary">
  >(api.characters.contentSummary, {
    variables: {
      characterId: character?.id || "",
    },
    skip: !character?.id,
  });

  const _saveUpdates = async () => {
    const variables: MutationUpdateCharacterArgs = {
      characterId: character?.id || "",
      personalityPrompt: prompt,
    };

    const response = await updateCharacter({
      variables,
      refetchQueries: [api.characters.content, api.characters.retrieve],
    });

    toast.show({
      message: "Updated",
      status: "success",
    });
  };

  const _embedContent = async () => {
    // embed the content
    if (!characterId) {
      return;
    }

    try {
      const response = await embedAllCharacterContent({
        variables: {
          characterId,
        },
        refetchQueries: [api.content.retrieve],
      });

      toast.show({
        message: "Content embedded",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: "Error",
        status: "error",
      });
    }
  };

  useEffect(() => {
    if (!contentId) {
      return;
    }

    dispatch(
      show("ContentModal", {
        contentId,
      })
    );
  }, [contentId]);

  const sources = sourceData?.getCharacterSources || [];
  const summary = contentSummaryData?.getCharacterContentSummary || null;

  return (
    <div
      style={{
        width: 450,
        flexShrink: 0,
        backgroundColor: theme.background,
        borderLeft: `1px solid ${theme.border}`,
        display: "flex",
        paddingBottom: 20,
        flexDirection: "column",
      }}
    >
      <Flex
        flex={1}
        style={{
          width: "100%",
          padding: 20,
          flexDirection: "column",
          overflowY: "auto",
        }}
      >
        <Text
          style={{
            fontSize: 24,
            fontWeight: "bold",
          }}
        >
          Personality
        </Text>

        <Divider style={{ margin: "10px 0" }} />

        <div>
          <label style={{ fontSize: "14px" }}>
            Prompt{" "}
            <Info message="This is appended as the context before we have GPT respond to the user's question." />
          </label>

          {/* test prompt as a text area */}
          <Textarea
            style={{
              marginTop: 10,
            }}
            noOfLines={6}
            value={prompt}
            onChange={(e) => setPrompt(e.target.value)}
          />
        </div>

        <Text
          style={{
            fontSize: 14,
            color: theme.text,
          }}
        >
          Content Summary{" "}
          <Info message="Only embedded content will be included in the answers" />
        </Text>

        <HStack style={{ marginBottom: 25, marginTop: 5 }}>
          <ContentSummaryInfo
            title="Content"
            numEmbedded={summary?.numberOfEmbeddedContent}
            total={summary?.numberOfContent}
          />
          <ContentSummaryInfo
            title="Items"
            numEmbedded={summary?.numberOfEmbeddedItems}
            total={summary?.numberOfItems}
          />
          <ContentSummaryInfo
            title="Products"
            numEmbedded={summary?.numberOfEmbeddedProducts}
            total={summary?.numberOfProducts}
          />
        </HStack>

        <div>
          <HStack style={{ marginBottom: 15 }}>
            <Flex flex={1}>
              <Touchable
                style={{
                  fontSize: 14,
                  marginRight: 10,
                  backgroundColor:
                    activeTab === "content" ? colors.primary : theme.background,
                  color: activeTab === "content" ? colors.white : theme.text,
                }}
                onClick={() => setActiveTab("content")}
              >
                Content
              </Touchable>

              <Touchable
                style={{
                  fontSize: 14,
                  backgroundColor:
                    activeTab === "products"
                      ? colors.primary
                      : theme.background,
                  color: activeTab === "products" ? colors.white : theme.text,
                }}
                onClick={() => setActiveTab("products")}
              >
                Products
              </Touchable>
            </Flex>

            <Touchable onClick={_embedContent} label="Embed" />
          </HStack>

          {activeTab === "content" && (
            <div>
              {sources.map((s) => (
                <SourceInfoRow source={s as any} key={s.id} />
              ))}
            </div>
          )}

          {activeTab === "products" && (
            <div>
              <ProductsSection />
            </div>
          )}
        </div>
      </Flex>
      <div style={{ padding: 20, borderTop: `1px solid ${theme.border}` }}>
        <Button
          onClick={_saveUpdates}
          variant="primary"
          style={{ width: "100%" }}
        >
          Save
        </Button>
      </div>
    </div>
  );
};

const SourceInfoRow = ({
  source,
}: {
  source: BaseSourceFields & { content: BaseContentFields[] };
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const theme = useTheme();
  const dispatch = useDispatch();
  const [getContentForSource, { data, loading }] = useLazyQuery<
    Pick<Query, "getContentForSource">
  >(api.content.forSource);

  const _getData = async () => {
    const newIsOpen = !isOpen;
    setIsOpen(newIsOpen);

    if (newIsOpen) {
      await getContentForSource({
        variables: {
          sourceId: source.id,
        },
      });
    }
  };

  const _showContentModal = () => {
    dispatch(
      show("ContentModal", {
        sourceId: source.id,
        source,
      })
    );
  };

  const content = data?.getContentForSource || [];

  return (
    <div>
      <HStack
        style={{
          padding: 10,
          cursor: "pointer",
          borderRadius: 10,
          alignItems: "center",
          marginBottom: 10,
          border: `1px solid ${theme.border}`,
          backgroundColor: theme.secondaryBackground,
        }}
        onClick={_getData}
      >
        <img
          src={source.sourceImageUrl || ""}
          style={{ width: 40, height: 40, borderRadius: 15, marginRight: 5 }}
        />

        <div style={{ flex: 1 }}>
          <Text noOfLines={2} style={{ flex: 1 }} fontSize="sm">
            {source.description}
          </Text>
          <Link
            color={theme.header}
            _hover={{
              color: colors.primary,
              textDecor: "underline",
            }}
            href={source.url}
            style={{
              flex: 1,
              marginTop: 5,
              fontSize: 14,
              color: colors.primary,
            }}
            target="_blank"
            rel="noreferrer"
          >
            View Source <i className="fas fa-external-link-alt" />
          </Link>
        </div>

        <Text>
          <Touchable
            style={{ marginBottom: 0 }}
            iconName={isOpen ? "fas fa-chevron-up" : "fas fa-chevron-down"}
            iconPosition="right"
          />
        </Text>
      </HStack>

      {isOpen && (
        <div style={{ paddingBottom: 25 }}>
          <div>
            <Touchable
              iconName="fas fa-plus"
              label="Add Content"
              onClick={_showContentModal}
            />

            {loading && (
              <Spinner
                style={{ marginLeft: 10 }}
                size="sm"
                color={colors.primary}
              />
            )}
          </div>

          {((content as BaseContentFields[]) || []).map((c) => (
            <ContentRow source={source} content={c} key={c.id} />
          ))}
        </div>
      )}
    </div>
  );
};

const ContentRow = ({
  content,
  source,
}: {
  content: Omit<BaseContentFields, "products" | "source">;
  source: BaseSourceFields;
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [deleteContent] = useMutation(api.content.delete);
  const toast = useMyToast();
  const [_s, setUrlSearchParam] = useSearchParams();

  const _editContent = () => {
    // update search param
    dispatch(show("ContentModal", { contentId: content.id, source }));
    // add contentId to the search param
    setUrlSearchParam({
      contentId: content.id,
    });
  };

  const _deleteContent = async () => {
    const response = await deleteContent({
      variables: {
        contentId: content.id,
      },
      refetchQueries: [api.content.retrieve, api.characters.sources],
    });

    if (response.data?.deleteContent) {
      toast.show({
        message: "Deleted",
        status: "success",
      });
    }
  };

  return (
    <div
      style={{
        padding: 10,
        border: `1px solid ${theme.border}`,
        borderRadius: 15,
        marginBottom: 10,
      }}
    >
      <a target="_blank" href={content.sourceUrl || "#"} rel="noreferrer">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
          }}
        >
          <img
            src={content.thumbnailImageUrl || ""}
            style={{
              width: 40,
              height: 40,
              objectFit: "cover",
              borderRadius: 10,
              marginRight: 10,
              flexShrink: 0,
            }}
          />

          <Text noOfLines={3} style={{ fontSize: "14px" }}>
            {content.title}
          </Text>
        </div>
      </a>
      <div
        style={{
          marginTop: 15,
          width: "100%",
          display: "flex",
          flex: 1,
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        <div
          style={{
            marginRight: 5,
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <div style={{ marginRight: 5 }}>
            <StatusTag
              iconName="fas fa-list-alt"
              type="none"
              labelStyle={{ fontSize: 12 }}
              label={content.numberOfItems || "-"}
              infoMessage="Number of items"
            />
          </div>

          <StatusTag
            iconName="fas fa-tags"
            type="none"
            labelStyle={{ fontSize: 12 }}
            label={content.numberOfProducts || "-"}
            infoMessage="Number of products"
          />
        </div>

        <StatusTag
          type={
            content.status === ContentStatusEnum.InProgress
              ? "warning"
              : "success"
          }
          infoMessage="Status"
          label={content.status}
        />

        <Flex
          style={{
            flex: 1,
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Touchable
            label="Edit"
            iconName="fas fa-pen"
            // iconPosition="left"
            onClick={_editContent}
            style={{ marginRight: 5 }}
          />

          <ActionSheet
            content={{ width: 150 }}
            popover={{ placement: "bottom-end" }}
            commands={[
              {
                label: "Delete",
                iconName: "fas fa-trash",
                onClick: _deleteContent,
                color: colors.red50,
                iconColor: colors.red50,
              },
            ]}
          >
            <Touchable iconName="fas fa-ellipsis-vertical" />
          </ActionSheet>
        </Flex>
      </div>

      {content?.lastEmbeddedAt && (
        <Text
          style={{
            marginTop: 10,
            fontSize: 12,
            color: theme.text,
            fontStyle: "italic",
            background: theme.secondaryBackground,
            padding: "5px 10px",
            borderRadius: 100,
          }}
        >
          Embedded {moment(content.lastEmbeddedAt).format("h:mma, MMM Do")}
        </Text>
      )}
    </div>
  );
};

const ProductsSection = () => {
  const { data } = useQuery<Pick<Query, "getProducts">>(api.products.list);
  const products = data?.getProducts || [];
  const theme = useTheme();
  const dispatch = useDispatch();

  const _editProduct = (product: BaseProductFields) => {
    dispatch(
      show("ProductModal", {
        product,
      })
    );
  };

  return (
    <div>
      {products.map((p) => (
        <HStack
          style={{
            padding: "15px 0",
            borderBottom: `1px solid ${theme.border}`,
          }}
        >
          <img
            style={{
              objectFit: "cover",
              width: 40,
              height: 40,
              borderRadius: 10,
              marginRight: 5,
            }}
            src={p.imageUrl || ""}
          />

          <div style={{ flex: 1 }}>
            <Text
              style={{
                color: theme.header,
              }}
            >
              {p.title}
            </Text>
            <Text
              style={{
                color: theme.text,
              }}
            >
              {p.description}
            </Text>
          </div>

          <div>
            <Touchable onClick={() => _editProduct(p)} iconName="fas fa-pen" />
          </div>
        </HStack>
      ))}
    </div>
  );
};

const ContentSummaryInfo = ({
  numEmbedded,
  total,
  title,
}: {
  title: string;
  numEmbedded?: number | null;
  total?: number | null;
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        flex: 1,
        borderRadius: 15,
        padding: 15,
        background: theme.secondaryBackground,
      }}
    >
      <Text
        style={{
          fontWeight: "bold",
          fontSize: 12,
          marginBottom: 5,
          color: theme.text,
        }}
      >
        {title} <Info message={`Embedded ${title} / total ${title}`} />
      </Text>
      {numEmbedded} of {total}
    </div>
  );
};
