import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';
import {ActiveCharacterState, ReduxState} from '../types';

// initial state
const initialState: ActiveCharacterState = {
  activeCharacter: null,
};

// actions
export const setActiveCharacter = createAction<
  ActiveCharacterState['activeCharacter']
>('SET_ACTIVE_CHARACTER');

// reducer
export const activeCharacterReducer = createReducer(initialState, builder => {
  builder.addCase(setActiveCharacter, (state, action) => {
    state.activeCharacter = action.payload;
  });
});

export const getActiveCharacter = createSelector(
  (state: ReduxState) => state.activeCharacter.activeCharacter,
  activeCharacter => activeCharacter,
);
