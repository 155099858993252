import React, { useState } from "react";
import Markdown from "react-markdown";
import { useSelector } from "react-redux";
import { BaseMessageFields, BaseMessageSectionFields } from "src/api/fragments";
import {
  MessageFromTypeEnum,
  MessageSectionTypeEnum,
} from "src/api/generated/types";
import { LargeProductCard } from "src/components/LargeProductCard";
import { useTheme } from "src/hooks";
import { getActiveCharacter } from "src/redux/reducers/activeCharacter";

const BORDER_RADIUS = 25;

type MessageProps = {
  message: BaseMessageFields;
};

const Message = ({ message }: MessageProps) => {
  if (message.sections?.length > 0) {
    return <MessageSection message={message} />;
  }

  return <MessageUserText message={message} />;
};

const MessageSection = ({ message }: { message: BaseMessageFields }) => {
  const theme = useTheme();
  const character = useSelector(getActiveCharacter);

  return (
    <div
      style={{
        marginTop: 20,
        borderColor: "#f2f2f2",
        borderBottomWidth: 1,
        marginBottom: 20,
        borderTopWidth: 1,
        padding: 20,
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
        }}
      >
        {character?.thumbnailImageUrl && (
          <img
            src={character?.thumbnailImageUrl}
            alt={character?.name || ""}
            style={{
              borderRadius: "50%",
              height: 25,
              width: 25,
              marginRight: 10,
            }}
          />
        )}

        <span
          style={{
            fontSize: 16,
            fontFamily: "Playfair Display",
            fontWeight: "bold",
          }}
        >
          {character?.name}
        </span>
      </div>

      {message.sections.map((section, index) => (
        <MessageSectionRow key={index} section={section} />
      ))}

      {message.content && message.content.length > 0 && (
        <div>
          {/* citations to each of these sources */}
          {message.content.map((c) => (
            <div
              style={{
                padding: 10,
                borderRadius: 10,
                display: "inline-block",
                margin: 5,
                backgroundColor: theme.secondaryBackground,
              }}
            >
              <a
                href={c.sourceUrl || ""}
                target="_blank"
                rel="noreferrer"
                style={{
                  color: theme.header,
                  fontSize: 14,
                  fontWeight: "500",
                }}
              >
                <i className="fas fa-link" style={{ marginRight: 5 }} />{" "}
                {c.title}
              </a>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const MessageSectionRow = ({
  section,
}: {
  section: BaseMessageSectionFields;
}) => {
  if (section.type === MessageSectionTypeEnum.Text) {
    return <MessageSectionText section={section} />;
  }

  if (section.type === MessageSectionTypeEnum.Image) {
    return <MessageSectionItems section={section} />;
  }

  return null;
};

const MessageSectionText = ({
  section,
}: {
  section: BaseMessageSectionFields;
}) => {
  const theme = useTheme();

  return (
    <>
      <h3
        style={{
          marginTop: 25,
          marginBottom: 10,
          fontSize: 20,
          fontWeight: "semibold",
          color: theme.header,
        }}
      >
        {section.title || ""}
      </h3>
      {/* <p
        style={{
          fontSize: 16,
          fontWeight: "500",
          color: theme.text,
          lineHeight: "24px",
          marginBottom: 5,
        }}
      >
        {section.content || ""}
      </p> */}
      <div className="markdown-content">
        <Markdown>{section.body || ""}</Markdown>
      </div>

      {/* add images if there are any */}
      {section.items && section.items.length > 0 && (
        <MessageSectionItems section={section} />
      )}
    </>
  );
};

const MessageSectionItems = ({
  section,
}: {
  section: BaseMessageSectionFields;
}) => {
  if (!section.items) {
    return null;
  }

  return (
    <div>
      {section.items.map((image) => (
        <a href={image.url || ""} target="_blank" rel="noreferrer">
          <LargeProductCard imageUrl={image.imageUrl || ""} />
        </a>
      ))}
    </div>
  );
};

const MessageUserText = ({
  message,
  style,
}: {
  message: BaseMessageFields;
  style?: React.CSSProperties;
}) => {
  const [isTouched, setTouched] = useState(true);
  const theme = useTheme();
  const isUser = message.fromType === MessageFromTypeEnum.Character;

  const handleUrlClick = (url: string) => {
    window.open(url.includes("https://") ? url : "https://" + url, "_blank");
  };

  return (
    <div
      style={{
        alignSelf: isUser ? "flex-start" : "flex-end",
        alignItems: "flex-end",
        margin: "10px 15px",
      }}
    >
      <div
        onClick={() => setTouched(!isTouched)}
        style={{
          borderRadius: BORDER_RADIUS,
          padding: "10px 15px",
          maxWidth: 275,
          backgroundColor: "#e5e5e5",
          ...style,
        }}
      >
        <p
          style={{
            fontSize: 16,
            fontWeight: "500",
            color: theme.text,
            lineHeight: "24px",
          }}
        >
          {message?.message}
        </p>
      </div>
    </div>
  );
};

export default React.memo(Message);
