import { useQuery } from "@apollo/client";
import { Box, Container, Text } from "@chakra-ui/react";
import { noop } from "lodash";
import { useDispatch } from "react-redux";
import { connectModal, InjectedProps, show } from "redux-modal";
import { api } from "src/api";
import { BaseProductFields } from "src/api/fragments";
import { Query } from "src/api/generated/types";
import { Modal } from "src/components/Modal";
import { Touchable } from "src/components/Touchable";
import { useMyToast, useTheme } from "src/hooks";

type Props = InjectedProps & {
  product?: BaseProductFields;
  onSuccess: (image: BaseProductFields) => void;
};

function _SearchProductsModal({
  handleHide,
  product,
  show: isVisible,
  onSuccess,
}: Props) {
  const toast = useMyToast();
  const theme = useTheme();

  const { data } = useQuery<Pick<Query, "getProducts">>(api.products.list);

  const products = data?.getProducts || [];
  const dispatch = useDispatch();

  const _onSuccess = (product: BaseProductFields) => {
    onSuccess(product);
    handleHide();
  };

  const _onEditProduct = (product: BaseProductFields) => (e: any) => {
    e.stopPropagation();
    e.preventDefault();

    dispatch(
      show("ProductModal", {
        product,
        onSuccess: noop,
      })
    );
  };

  return (
    <Modal
      title={<>Search Products</>}
      isVisible={isVisible}
      handleHide={handleHide}
      preserveScrollBarGap
      // footerProps={{
      //   borderTop: `1px solid ${theme.border}`,
      //   background: colors.white,
      // }}
      // Footer={
      //   <Button width="100%" variant="primary" onClick={_onSubmit}>
      //     Save
      //   </Button>
      // }
    >
      <Container
        padding="0px"
        marginTop="0px !important"
        style={{
          maxHeight: "100vh",
          height: "100%",
          paddingBottom: 100,
          overflowY: "scroll",
        }}
      >
        {products.map((product) => (
          <Box
            key={product.id}
            _hover={{
              bg: theme.secondaryBackground,
            }}
            onClick={() => _onSuccess(product)}
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              cursor: "pointer",
              padding: 5,
              borderRadius: 10,
            }}
          >
            <img
              src={product.imageUrl || ""}
              style={{
                width: 50,
                height: 50,
                borderRadius: 10,
                marginRight: 10,
              }}
            />

            <div style={{ flex: 1 }}>
              <Text
                style={{
                  color: theme.header,
                }}
              >
                {product.title}
              </Text>
              <Text
                style={{
                  color: theme.text,
                }}
              >
                {product.description}
              </Text>
            </div>

            <Touchable
              label="Edit"
              iconName="fas fa-pen"
              onClick={_onEditProduct(product)}
            />
          </Box>
        ))}

        <br />
      </Container>
    </Modal>
  );
}

export const SearchProductsModal = connectModal({
  name: "SearchProductsModal",
})(_SearchProductsModal);
