import { useMutation, useQuery } from "@apollo/client";
import { HStack, Text } from "@chakra-ui/react";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { api } from "src/api";
import { BaseConversationFields, BaseMessageFields } from "src/api/fragments";
import {
  Mutation,
  MutationStartConversationArgs,
  Query,
} from "src/api/generated/types";
import { Button } from "src/components/Button";
import { ContentItemModal } from "src/components/modals/ContentItemModal/ContentItemModal";
import { ContentModal } from "src/components/modals/ContentModal/ContentModal";
import { ContentProductModal } from "src/components/modals/ContentProductModal/ContentProductModal";
import { ProductModal } from "src/components/modals/ProductModal/ProductModal";
import { SearchProductsModal } from "src/components/modals/SearchProductsModal/SearchProductsModal";
import { useTheme } from "src/hooks";
import { getActiveCharacter } from "src/redux/reducers/activeCharacter";
import { getConversation, setConversation } from "src/redux/reducers/chat";
import Message from "./Message";
import { MessageInput } from "./MessageInput";

export default function Home() {
  const character = useSelector(getActiveCharacter);
  const dispatch = useDispatch();
  const theme = useTheme();

  const { conversationId, characterId } = useParams();

  const navigate = useNavigate();

  const { data: existingConvoData } = useQuery<Pick<Query, "getConversation">>(
    api.conversations.retrieve,
    {
      variables: {
        conversationId,
      },
      skip: !conversationId,
    }
  );

  const existingConversation = existingConvoData?.getConversation ?? null;

  const [startConversation, { data }] = useMutation<
    Pick<Mutation, "startConversation">
  >(api.conversations.start);

  const startChat = async () => {
    if (!character || !character.id) {
      return;
    }

    try {
      const variables: MutationStartConversationArgs = {
        characterId: character.id,
      };

      const response = await startConversation({
        variables,
        refetchQueries: [api.conversations.messages],
      });

      const data = response.data?.startConversation;

      if (!data) {
        return;
      }

      dispatch(setConversation(data as BaseConversationFields));

      navigate(`/dashboard/${characterId}/chats/${data.id}`);
    } catch (err) {
      console.log(err);
      //
    }
  };

  useEffect(() => {
    dispatch(setConversation(existingConversation as BaseConversationFields));
  }, [existingConversation]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        flex: 1,
        fontFamily: "Arial, sans-serif",
      }}
    >
      <ContentModal />
      <ContentItemModal />
      <ProductModal />
      <ContentProductModal />
      <SearchProductsModal />

      {/* Main Content */}
      <div
        style={{
          paddingBottom: 10,
          flex: 1,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          height: "100vh",
          overflow: "scroll",
        }}
      >
        <HStack
          style={{
            padding: "0 20px",
            height: 75,
            alignSelf: "center",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            width: "100%",
            borderBottom: `1px solid ${theme.secondaryBackground}`,
          }}
        >
          <img
            src={character?.thumbnailImageUrl || ""}
            style={{ width: 50, height: 50, borderRadius: 50 }}
          />
          <Text
            style={{
              flex: 1,
              marginLeft: 5,
              fontSize: 24,
              fontFamily: "Playfair Display",
              fontWeight: "bold",
            }}
          >
            {character?.name}
          </Text>

          <Button width="50px !important" variant="primary" onClick={startChat}>
            Chat
          </Button>
        </HStack>

        <div
          style={{
            flex: 1,
            height: "calc(100vh - 20px - 100px - 125px)",
            display: "flex",
            marginBottom: 15,
            width: "100%",
          }}
        >
          <Messages />
        </div>

        <div style={{ padding: "0 10px", width: "100%" }}>
          <MessageInput />
        </div>
      </div>
    </div>
  );
}

const Messages = () => {
  const conversation = useSelector(getConversation);

  const variables = useMemo(
    () => ({
      conversationId: conversation?.id,
    }),
    [conversation?.id]
  );

  const { data } = useQuery<Pick<Query, "getMessages">>(
    api.conversations.messages,
    {
      variables,
      skip: !variables.conversationId,
    }
  );

  const messages = (data?.getMessages || []) as BaseMessageFields[];

  // console.log(messages);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        flex: 1,
        width: "100%",
        paddingTop: 15,
        overflow: "scroll",
      }}
    >
      {messages.map((message) => (
        <Message key={message.id} message={message} />
      ))}
    </div>
  );
};
