import {createAction, createReducer, createSelector} from '@reduxjs/toolkit';
import {GlobalState, ReduxState} from '../types';

// initial state
const initialState: GlobalState = {
  theme: 'dark',
  isKeyboardShowing: false,
  keyboardHeight: 0,
};

// actions
export const setTheme = createAction<'light' | 'dark'>('SET_THEME');
export const setKeyboard = createAction<{
  isShowing: boolean;
  keyboardHeight: number;
}>('SET_KEYBOARD');

// reducer
export const globalStateReducer = createReducer(initialState, builder => {
  builder
    .addCase(setTheme, (state, action) => {
      state.theme = action.payload;
    })
    .addCase(setKeyboard, (state, action) => {
      state.isKeyboardShowing = action.payload.isShowing;
      state.keyboardHeight = action.payload.keyboardHeight;
    });
});

export const getTheme = createSelector(
  (state: ReduxState) => state.global.theme,
  theme => theme || 'dark',
);

export const getKeyboard = createSelector(
  (state: ReduxState) => state.global,
  global => ({
    isShowing: global.isKeyboardShowing,
    keyboardHeight: global.keyboardHeight,
  }),
);
