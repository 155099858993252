import { onAuthStateChanged, signInWithCustomToken } from "firebase/auth";
import { compose } from "lodash/fp";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { setUserAuthStateChanged } from "src/redux/reducers/user";
import { auth } from "src/utils/firebase";
import { useMe } from "./useMe";

export const useAuth = () => {
  const dispatch = useDispatch();
  const [search] = useSearchParams();
  const _setAuthStatus = compose(dispatch, setUserAuthStateChanged);
  const { me } = useMe();

  useEffect(() => {
    _setAuthStatus("NOT_LOADED");

    (async () => {
      const token = search.get("authToken");

      if (token) {
        await signInWithCustomToken(auth, token);
        _setAuthStatus("LOGGED_IN");
      }

      onAuthStateChanged(auth, async (user) => {
        if (user) {
          _setAuthStatus("LOGGED_IN");
        } else {
          _setAuthStatus("NOT_LOGGED_IN");
        }
      });
    })();
  }, []);
};
