import { ApolloProvider } from "@apollo/client";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { theme } from "src/theme";
import { apolloClient } from "./api/apollo";
import { Navigation } from "./navigation";
import { store } from "./redux/store";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ErrorBoundary } from "./components/ErrorBoundary";

const queryClient = new QueryClient();

const App = () => {
  return (
    <>
      <ChakraProvider theme={theme}>
        <ErrorBoundary>
          <QueryClientProvider client={queryClient}>
            <ApolloProvider client={apolloClient}>
              <Provider store={store}>
                <Navigation />
              </Provider>
            </ApolloProvider>
          </QueryClientProvider>
        </ErrorBoundary>
      </ChakraProvider>
    </>
  );
};

export default App;
