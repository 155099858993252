import { createAction, createReducer, createSelector } from "@reduxjs/toolkit";
import { ChatState, ReduxState } from "../types";

// initial state
const initialState: ChatState = {
  isShowingChatInput: false,
  isBotResponding: false,
  message: "",
  character: null,
  conversation: null,
  messages: [],
};

// actions
export const setIsShowingChatInput = createAction<boolean>(
  "SET_IS_SHOWING_CHAT_INPUT"
);
export const setMessage = createAction<string>("SET_MESSAGE");
export const setCharacter =
  createAction<ChatState["character"]>("SET_CHARACTER");
export const setConversation =
  createAction<ChatState["conversation"]>("SET_CONVERSATION");
export const setMessages = createAction<ChatState["messages"]>("SET_MESSAGES");
export const setIsBotResponding = createAction<boolean>(
  "SET_IS_BOT_RESPONDING"
);
export const addMessage = createAction<ChatState["messages"][0]>("ADD_MESSAGE");
export const removeMessage = createAction<string>("REMOVE_MESSAGE");
// reducer
export const chatReducer = createReducer(initialState, (builder) => {
  builder
    .addCase(setIsShowingChatInput, (state, action) => {
      state.isShowingChatInput = action.payload;
    })
    .addCase(setMessage, (state, action) => {
      state.message = action.payload;
    })
    .addCase(setCharacter, (state, action) => {
      state.character = action.payload;
    })
    .addCase(setConversation, (state, action) => {
      state.conversation = action.payload;
    })
    .addCase(setMessages, (state, action) => {
      state.messages = action.payload;
    })
    .addCase(setIsBotResponding, (state, action) => {
      state.isBotResponding = action.payload;
    })
    .addCase(addMessage, (state, action) => {
      const newMessages = [...state.messages, action.payload];
      state.messages = newMessages;
    })
    // remove the message by idempotency key
    .addCase(removeMessage, (state, action) => {
      state.messages = state.messages.filter(
        (message) => message.idempotencyKey !== action.payload
      );
    });
});

export const getIsShowingChatInput = createSelector(
  (state: ReduxState) => state.chat.isShowingChatInput,
  (isShowingChatInput) => isShowingChatInput || false
);

export const getMessage = createSelector(
  (state: ReduxState) => state.chat.message,
  (message) => message || ""
);

export const getCharacter = createSelector(
  (state: ReduxState) => state.chat.character,
  (character) => character || null
);

export const getConversation = createSelector(
  (state: ReduxState) => state.chat.conversation,
  (conversation) => conversation || null
);

export const getMessages = createSelector(
  (state: ReduxState) => state.chat.messages,
  (messages) => messages || []
);

export const getIsBotResponding = createSelector(
  (state: ReduxState) => state.chat.isBotResponding,
  (isBotResponding) => isBotResponding || false
);
