import { BaseContentFields } from "src/api/fragments";
import {
  ContentItemInput,
  ContentItemTypeEnum,
  ContentProductInput,
  ContentStatusEnum,
  ContentTypeEnum,
} from "src/api/generated/types";
import { D } from "src/utils/helpers";
import * as yup from "yup";

const itemSchema = yup.object().shape({
  id: yup.string().required("ID is required.").nullable(),
  type: yup.string().required("Type is required.").nullable(),
  imageUrl: yup.string().optional().nullable(),
  title: yup.string().optional().nullable(),
  description: yup.string().optional().nullable(),
  gptDescription: yup.string().optional().nullable(),
  productIds: yup.array().of(yup.string()).nullable(),
});

const discountSchema = yup.object().shape({
  type: yup.string().required("Type is required.").nullable(),
  amountCents: yup.number().default(0),
  newPriceCents: yup.number().default(0),
  percentage: yup.number().default(0),
});

const productSchema = yup.object().shape({
  id: yup.string().required("ID is required.").nullable(),
  productId: yup.string().nullable(),
  title: yup.string().nullable(),
  vendorName: yup.string().optional().nullable(),
  brandName: yup.string().optional().nullable(),
  imageUrl: yup.string().optional().nullable(),
  shoppingUrl: yup.string().optional().nullable(),
  contentDescription: yup.string().optional().nullable(),
  gptDescription: yup.string().optional().nullable(),
  productDescription: yup.string().optional().nullable(),
  discount: discountSchema.optional().nullable(),
  price: yup.number().optional().nullable(),
});

export const schema = yup.object().shape({
  type: yup.string().oneOf(Object.values(ContentTypeEnum)).nullable(),
  status: yup.string().oneOf(Object.values(ContentStatusEnum)).nullable(),
  url: yup.string().nullable(),
  title: yup.string().nullable(),
  content: yup.string().nullable(),
  items: yup.array().of(itemSchema).nullable(),
  products: yup.array().of(productSchema).nullable(),
});

export type ItemFormValue = {
  id: string;
  type: ContentItemTypeEnum;
  imageUrl?: string | null;
  title: string;
  description: string;
  gptDescription: string | null;
  productIds: string[];
  substituteProductIds: string[];
  keywords: string[] | null;
};

export type ProductFormValue = {
  id: string;
  productId: string | null;
  title: string;
  vendorName: string;
  brandName: string | null;
  imageUrl: string;
  shoppingUrl: string;
  contentDescription: string;
  gptDescription: string | null;
  productDescription: string | null;
  discount: {
    type: string;
    amountCents: number;
    newPriceCents: number;
    percentage: number;
  } | null;
  keywords: string[] | null;
  price: number;
  currency: string | null;
};

export type FormValues = {
  type: ContentTypeEnum;
  status: ContentStatusEnum;
  url: string;
  title: string;
  content: string;
  items: ItemFormValue[];
  products: ProductFormValue[];
};

export const DEFAULT_VALUES: FormValues = {
  type: ContentTypeEnum.Blog,
  status: ContentStatusEnum.InProgress,
  url: "",
  title: "",
  content: "",
  items: [],
  products: [],
};

export const TO_ITEM_FORM_VALUES = (
  img: BaseContentFields["items"][0],
  index: number
): ItemFormValue => ({
  id: img.id,
  type: img.type,
  imageUrl: img.imageUrl || "",
  title: img.title || "",
  description: img.description || "",
  productIds: img.productIds ?? [],
  substituteProductIds: img.substituteProductIds ?? [],
  keywords: img.keywords ?? [],
  gptDescription: img.gptDescription || "",
});

export const TO_PRODUCT_FORM_VALUES = (
  product: BaseContentFields["products"][0],
  index: number
): ProductFormValue => ({
  id: product.id,
  productId: product.productId || null,
  title: product.title || "",
  brandName: product.brandName || "",
  vendorName: product.vendorName,
  imageUrl: product.imageUrl || "",
  shoppingUrl: product.shoppingUrl,
  gptDescription: product.gptDescription || "",
  productDescription: product.productDescription || "",
  contentDescription: product.contentDescription || "",
  discount: product.discount || null,
  price: D(product.priceCents ?? 0).toUnit(),
  keywords: product.keywords ?? [],
  currency: product.currency || "USD",
});

export const TO_ITEM_GQL = (
  img: ItemFormValue,
  i: number
): ContentItemInput => ({
  id: img.id,
  imageUrl: img.imageUrl,
  type: img.type,
  position: i,
  title: img.title,
  description: img.description,
  gptDescription: img.gptDescription || null,
  productIds: img.productIds,
  substituteProductIds: img.substituteProductIds || [],
  keywords: img.keywords || [],
});

export const TO_PRODUCT_GQL = (
  product: ProductFormValue,
  i: number
): ContentProductInput => ({
  id: product.id,
  productId: product.productId || null,
  title: product.title || "",
  position: i,
  priceCents: D(product.price * 100).getAmount(),
  vendorName: product.vendorName,
  brandName: product.brandName || "",
  imageUrl: product.imageUrl || "",
  shoppingUrl: product.shoppingUrl,
  keywords: product.keywords || [],
  contentDescription: product.contentDescription,
  productDescription: product.productDescription || null,
  gptDescription: product.gptDescription || null,
  discount: product.discount || null,
  currency: product.currency || "USD",
});
