import { useLazyQuery } from "@apollo/client";
import { Button, Container, Flex, HStack, Text } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import {
  Query,
  QueryDescribeImageArgs,
  QueryScrapeProductUrlArgs,
} from "src/api/generated/types";
import { Input, Select, Textarea } from "src/components/Form";
import { ImageUpload } from "src/components/ImageUpload";
import { Modal } from "src/components/Modal";
import { Touchable } from "src/components/Touchable";
import { useMyToast, useTheme } from "src/hooks";
import { colors } from "src/theme";
import { ProductFormValue } from "../ContentModal/form";

type Props = InjectedProps & {
  product?: ProductFormValue;
  onSuccess: (image: ProductFormValue) => void;
};

function _ContentProductModal({
  handleHide,
  product,
  show: isVisible,
  onSuccess,
}: Props) {
  const toast = useMyToast();

  const tempImage = product?.imageUrl || "";
  const theme = useTheme();

  // if it starts with //, replace that with https://
  const _imageUrl = tempImage.startsWith("//")
    ? "https:" + tempImage
    : tempImage;

  const [imageUrl, setImageUrl] = useState(_imageUrl || "");
  const [description, setDescription] = useState(
    product?.contentDescription || ""
  );
  const [gptDescription, setGptDescription] = useState(
    product?.gptDescription || ""
  );
  const [productDescription, setProductDescription] = useState(
    product?.productDescription || ""
  );

  const [title, setTitle] = useState(product?.title || "");
  const [shoppingUrl, setShoppingUrl] = useState(product?.shoppingUrl || "");
  const [vendorName, setVendorName] = useState(product?.vendorName || "");
  const [brandName, setBrandName] = useState(product?.brandName || "");
  const [price, setPrice] = useState<string>((product?.price || 0).toString());
  const [discount, setDiscount] = useState(product?.discount || null);
  const [keywords, setKeywords] = useState(product?.keywords || []);
  const [keywordSearch, setKeywordSearch] = useState("");

  const [describeImage] = useLazyQuery<Pick<Query, "describeImage">>(
    api.content.describeImage
  );

  const [scrapeProductUrl] = useLazyQuery<Pick<Query, "scrapeProductUrl">>(
    api.content.scrapeProductUrl
  );

  const setFileUpload = ({ name, url }: { name: string; url: string }) => {
    setImageUrl(url);
  };

  const _onSubmit = async function () {
    try {
      // just call the on success to pass back the content of the product
      onSuccess({
        id: product?.id || "",
        price: parseFloat(price ?? product?.price),
        brandName,
        productId: product?.productId || "",
        imageUrl,
        gptDescription: gptDescription,
        productDescription: productDescription,
        contentDescription: description,
        currency: "USD",
        discount: null,
        title,
        shoppingUrl,
        vendorName,
        keywords,
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const _describeImage = async () => {
    if (!imageUrl) {
      toast.show({
        message: "Please upload an image",
        status: "error",
      });
      return;
    }

    const params: QueryDescribeImageArgs = {
      url: imageUrl,
    };

    const response = await describeImage({
      variables: params,
    });

    console.log(response);

    const data = response.data?.describeImage;

    if (data) {
      const newDescription = data.description;

      setGptDescription(newDescription || "");
      setKeywords([...keywords, ...(data.keywords || [])]);
    }
  };

  const _scrapeProduct = async () => {
    if (!shoppingUrl) {
      toast.show({
        message: "Please add the product detail URL.",
        status: "error",
      });
      return;
    }

    const params: QueryScrapeProductUrlArgs = {
      url: shoppingUrl,
    };

    const response = await scrapeProductUrl({
      variables: params,
      fetchPolicy: "network-only",
    });

    console.log(response);

    const data = response.data?.scrapeProductUrl;

    if (data) {
      const newDescription = description
        ? description + "\n\n" + data.description
        : data.description;

      setTitle(data.title || "");
      setVendorName(data.vendorName || "");
      setBrandName(data.brandName || "");
      setPrice((data.priceCents ?? 0 / 100)?.toString() || "");
      setImageUrl(data.imageUrl || "");
      setDescription(newDescription || "");

      // TODO: would be nice to auto-generate the keywords here as well but not necessary
    }
  };

  const selectedKeywordOptions = useMemo(
    () =>
      keywords.map((k) => ({
        label: k,
        value: k,
      })),
    [keywords]
  );

  const keywordOptions = useMemo(
    () =>
      [
        ...selectedKeywordOptions,
        {
          label: keywordSearch,
          value: keywordSearch,
        },
      ].filter((k) => !!k.value),
    [selectedKeywordOptions, keywordSearch]
  );

  return (
    <Modal
      title={<>Content Product</>}
      isVisible={isVisible}
      handleHide={handleHide}
      preserveScrollBarGap
      Footer={
        <HStack
          style={{
            width: "100%",
            paddingTop: 15,
            borderTop: `1px solid ${theme.border}`,
          }}
        >
          <Flex flex={1}>
            <Touchable
              label="Scrape"
              iconName="fas fa-spider"
              onClick={_scrapeProduct}
            />

            <Touchable
              label="Describe"
              iconName="fas fa-pencil-alt"
              onClick={_describeImage}
            />
          </Flex>
          <Button variant="primary" marginBottom="0" onClick={_onSubmit}>
            Save
          </Button>
        </HStack>
      }
      minW="750px !important"
    >
      <Container
        padding="0px"
        marginTop="0px !important"
        maxH="70vh"
        overflowY="scroll"
      >
        <Text fontSize="sm" color="gray.500" marginBottom="1rem">
          Note: this will edit the product for this specific piece of content,
          but not update the product globally.
        </Text>

        <ImageUpload
          fileUpload={{
            name: title,
            url: imageUrl,
          }}
          onFileUpload={setFileUpload}
        />

        <br />

        <Input
          label="Product URL"
          value={shoppingUrl}
          onChange={(e) => setShoppingUrl(e.target.value)}
        />

        <a
          href={shoppingUrl}
          target="_blank"
          rel="noreferrer"
          style={{ fontWeight: "bold", color: colors.primary }}
        >
          <i className="fas fa-external-link-alt" /> View Product
        </a>

        <br />

        <Input
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <Input
          label="Vendor"
          value={vendorName}
          onChange={(e) => setVendorName(e.target.value)}
        />

        <Input
          label="Brand"
          value={brandName}
          onChange={(e) => setBrandName(e.target.value)}
        />

        <Input
          label="Price"
          value={price}
          type="number"
          onChange={(e) => setPrice(e.target.value)}
        />

        <Textarea
          label="Content Description"
          subtitle="This is how the piece of content describes the product."
          value={description}
          textareaProps={{
            height: 150,
          }}
          onChange={(e) => setDescription(e.target.value)}
        />

        <Textarea
          label="Product Description"
          subtitle="The description of the product from the website."
          value={productDescription}
          textareaProps={{
            height: 75,
          }}
          onChange={(e) => setProductDescription(e.target.value)}
        />

        <Textarea
          label="GPT Description"
          subtitle="GPT's description."
          value={gptDescription}
          textareaProps={{
            height: 150,
          }}
          onChange={(e) => setGptDescription(e.target.value)}
        />

        <Select
          label="Keywords"
          options={keywordOptions}
          selectProps={{
            isMulti: true,
            onInputChange: (e) => setKeywordSearch(e),
            value: selectedKeywordOptions,

            onChange: (o: any, a) => {
              const newKeywords = [...o.map((k: any) => k.value)];
              setKeywords(newKeywords);
            },
          }}
        />

        <br />
      </Container>
    </Modal>
  );
}
export const ContentProductModal = connectModal({
  name: "ContentProductModal",
})(_ContentProductModal);
