import { useMutation } from "@apollo/client";
import { Button, Container } from "@chakra-ui/react";
import { useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import { BaseProductFields } from "src/api/fragments";
import {
  MutationCreateProductArgs,
  MutationUpdateProductArgs,
} from "src/api/generated/types";
import { Input, Textarea } from "src/components/Form";
import { ImageUpload } from "src/components/ImageUpload";
import { Modal } from "src/components/Modal";
import { useMyToast } from "src/hooks";
import { D } from "src/utils/helpers";

type Props = InjectedProps & {
  product?: BaseProductFields;
  onSuccess: (image: BaseProductFields) => void;
};

function _ProductModal({
  handleHide,
  product,
  show: isVisible,
  onSuccess,
}: Props) {
  const toast = useMyToast();

  const [createProduct] = useMutation(api.products.create);
  const [updateProduct] = useMutation(api.products.update);

  const [imageUrl, setImageUrl] = useState(product?.imageUrl || "");
  const [description, setDescription] = useState(product?.description || "");
  const [title, setTitle] = useState(product?.title || "");
  const [shoppingUrl, setShoppingUrl] = useState(product?.shoppingUrl || "");
  const [vendorName, setVendorName] = useState(product?.vendorName || "");
  const [brandName, setBrandName] = useState(product?.brandName || "");
  const [price, setPrice] = useState<string>(
    D(product?.priceCents || 0)
      .toUnit()
      .toString()
  );

  const setFileUpload = ({ name, url }: { name: string; url: string }) => {
    setImageUrl(url);
  };

  const _onSubmit = async function () {
    try {
      if (product) {
        // update it
        const variables: MutationUpdateProductArgs = {
          imageUrl,
          description,
          title,
          brandName,
          priceCents: D(parseFloat(price) * 100).getAmount(),
          shoppingUrl,
          vendorName,
          productId: product.id,
        };

        const response = await updateProduct({
          variables,
          refetchQueries: [api.products.list],
        });

        const newProduct = response.data?.updateProduct;

        if (!newProduct) {
          throw new Error("An error occurred.");
        }

        onSuccess(newProduct);

        handleHide();

        return;
      }

      // otherwise create the product
      const params: MutationCreateProductArgs = {
        imageUrl,
        description,
        brandName,
        title,
        priceCents: D(parseFloat(price) * 100).getAmount(),
        shoppingUrl,
        vendorName,
      };

      const response = await createProduct({
        variables: params,
        refetchQueries: [api.products.list],
      });

      const newProduct = response.data?.createProduct;

      if (!newProduct) {
        throw new Error("An error occurred.");
      }

      onSuccess(newProduct);

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  return (
    <Modal
      title={<>Product Modal</>}
      isVisible={isVisible}
      handleHide={handleHide}
      preserveScrollBarGap
      Footer={
        <Button
          width="100%"
          variant="primary"
          marginBottom="1rem"
          onClick={_onSubmit}
        >
          Save
        </Button>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <ImageUpload
          fileUpload={{
            name: title,
            url: imageUrl,
          }}
          onFileUpload={setFileUpload}
        />

        <br />

        <Input
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <Input
          label="Vendor"
          subtitle="Ex. Macy's"
          value={vendorName}
          onChange={(e) => setVendorName(e.target.value)}
        />

        <Input
          label="Brand"
          subtitle="Ex. Nike"
          value={brandName}
          onChange={(e) => setBrandName(e.target.value)}
        />

        <Input
          label="Product URL"
          value={shoppingUrl}
          onChange={(e) => setShoppingUrl(e.target.value)}
        />

        <Input
          label="Price"
          value={price}
          type="number"
          onChange={(e) => setPrice(e.target.value)}
        />

        <Textarea
          label="Description"
          subtitle="This is what we will use to determine if this image is relevant to a user's question."
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />

        <br />
      </Container>
    </Modal>
  );
}

export const ProductModal = connectModal({
  name: "ProductModal",
})(_ProductModal);
