import { useEffect } from "react";
import {
  BrowserRouter,
  Navigate,
  Outlet,
  Route,
  Routes,
  useLocation,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";

import { useApolloClient, useQuery } from "@apollo/client";
import { Spinner } from "@chakra-ui/react";
import { signOut } from "firebase/auth";
import { useDispatch, useSelector } from "react-redux";
import { api } from "src/api";
import { BaseCharacterFields } from "src/api/fragments";
import { Query } from "src/api/generated/types";
import { useTheme } from "src/hooks/useTheme";
import { setActiveCharacter } from "src/redux/reducers/activeCharacter";
import { getUserAuthStatus } from "src/redux/reducers/user";
import { AuthStatus } from "src/redux/types";
import { auth } from "src/utils/firebase";
import Home from "src/views/Home";
import { CharacterDetailSidebar } from "src/views/Home/CharacterDetailSidebar";
import Characters from "src/views/Home/Characters";
import { Login } from "src/views/Login";

const ProtectedRoute = ({
  authStatus,
  children,
}: {
  authStatus: AuthStatus;
  children: JSX.Element;
}) => {
  const theme = useTheme();

  if (authStatus === "LOADING" || authStatus === "NOT_LOADED") {
    return (
      <div
        style={{
          display: "flex",
          height: "100vh",
          width: "100vw",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          backgroundColor: theme.background,
        }}
      >
        <Spinner color={theme.header} />
      </div>
    );
  }

  if (authStatus === "NOT_LOGGED_IN") {
    const path = window.location.pathname;
    // add the search params if there are any as well
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchString = params.toString();
    return (
      <Navigate
        to={`/login?redirect=${path}${searchString ? "&" + searchString : ""}`}
        replace
      />
    );
  }

  return children;
};

const DashboardRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<CharacterDashboardContainer />}>
        <Route path="" element={<Home />} />
      </Route>

      <Route path=":characterId" element={<CharacterDashboardContainer />}>
        <Route path="" element={<Home />} />
        <Route path="chats">
          <Route path="" element={<Home />} />
          <Route path=":conversationId" element={<Home />} />
        </Route>
      </Route>
    </Routes>
  );
};

const MainPage = () => {
  const authStatus = useSelector(getUserAuthStatus);
  const theme = useTheme();

  if (authStatus === "LOGGED_IN") {
    return <Navigate to="/app" />;
  }

  if (authStatus === "NOT_LOGGED_IN") {
    return <Navigate to="/login" />;
  }

  return (
    <div
      style={{
        display: "flex",
        height: "100vh",
        width: "100vw",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor: theme.background,
      }}
    >
      <Spinner color={theme.header} />
    </div>
  );
};

const CharacterDashboardContainer = () => {
  const authStatus = useSelector(getUserAuthStatus);
  const theme = useTheme();
  const location = useLocation();
  const { characterId } = useParams();
  const dispatch = useDispatch();

  const { data } = useQuery<Pick<Query, "getCharacter">>(
    api.characters.retrieve,
    {
      variables: {
        characterId,
      },
      skip: !characterId,
    }
  );

  useEffect(() => {
    if (!data?.getCharacter) return;
    dispatch(setActiveCharacter(data?.getCharacter as BaseCharacterFields));
  }, [data?.getCharacter]);

  return (
    <ProtectedRoute authStatus={authStatus}>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          height: "100vh",
          width: "100%",
          fontFamily: "SF Pro, sans-serif",
        }}
      >
        <Characters />
        <div style={{ flex: 1 }}>
          <Outlet />
        </div>
        {characterId ? <CharacterDetailSidebar /> : null}
      </div>
    </ProtectedRoute>
  );
};

export const MainRoutes = () => {
  const { pathname } = useLocation();
  const status = useSelector(getUserAuthStatus);
  const isLoggedIn = status === "LOGGED_IN";
  const authStatus = useSelector(getUserAuthStatus);

  const theme = useTheme();

  useAuth();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const dispatch = useDispatch();

  useEffect(() => {
    // if dark, set the html to have the theme.background color
    document.documentElement.style.backgroundColor = theme.background;
  }, [theme.background]);

  return (
    <Routes>
      <Route path="" element={<MainPage />} />
      <Route path="login" element={<Login />} />
      <Route path="logout" element={<Logout />} />
      <Route path="dashboard/*" element={<DashboardRoutes />} />
    </Routes>
  );
};

const Logout = () => {
  const navigate = useNavigate();
  const apolloClient = useApolloClient();

  useEffect(() => {
    signOut(auth)
      .then(() => apolloClient.cache.reset())
      .then(() => navigate("/login"));
  }, []);

  return null;
};

export const Navigation = () => (
  <BrowserRouter>
    <MainRoutes />
  </BrowserRouter>
);
