import { useCallback, useMemo } from "react";
import { useDispatch } from "react-redux";
import { colors } from "src/theme";

export const useTheme = () => {
  const theme = "light";
  const dispatch = useDispatch();

  const _toggleDarkMode = useCallback(async () => {
    try {
      const newTheme = theme === "light" ? "dark" : "light";
      await window.localStorage.setItem("modiste:theme", newTheme);
      //   dispatch(setTheme(newTheme));
    } catch (error) {
      console.log(error);
    }
  }, [theme]);

  const data = useMemo(
    () => ({
      bgPrimary: theme === "light" ? "#DFDCFB" : "#1a115f",
      bgRed: theme === "light" ? colors.red100 : colors.red20,
      bgPrimaryLight: theme === "light" ? "#F4F2FF" : "#1A093D",
      background: theme === "light" ? colors.white : colors.black,
      medBackground: theme === "light" ? colors.gray90 : colors.gray00,
      blueBackground:
        theme === "light" ? colors.lightBlue100 : colors.lightBlue10,
      greenBackground: theme === "light" ? colors.green100 : colors.green10,
      secondaryBackground: theme === "light" ? "#f7f6f5" : "#f7f6f5",
      secondaryBackground2: theme === "light" ? colors.gray80 : colors.gray20,
      ternaryBackground:
        theme === "light"
          ? colors.bgLightNeutralTertiary
          : colors.bgDarkNeutralTertiary,
      darkerBackground: theme === "light" ? colors.gray50 : colors.gray50,
      secondaryButtonBackground:
        theme === "light" ? colors.gray85 : colors.gray10,
      header:
        theme === "light"
          ? colors.textLightNeutralPrimary
          : colors.textDarkNeutralPrimary,
      theme,
      activityIndicator: theme === "light" ? colors.gray60 : colors.gray50,
      textSecondary:
        theme === "light"
          ? colors.textLightNeutralSecondary
          : colors.textDarkNeutralSecondary,
      textSubtle:
        theme === "light"
          ? colors.textLightNeutralSubtle
          : colors.textDarkNeutralSubtle,
      textPrimary:
        theme === "light"
          ? colors.textLightNeutralPrimary
          : colors.textDarkNeutralPrimary,
      text: theme === "light" ? "#7c7b7a" : colors.gray60,
      placeholder: theme === "light" ? colors.gray60 : colors.gray30,
      border: theme === "light" ? "#e5e5e5" : "#e5e5e5",
      borderDark: theme === "light" ? colors.gray80 : colors.gray20,
      toggleDarkMode: _toggleDarkMode,
    }),
    [theme, _toggleDarkMode]
  );

  return data;
};
