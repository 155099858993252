import { configureStore } from "@reduxjs/toolkit";

import { hasValue } from "src/core";
import { reducers } from "./reducers";

const middleware = [].filter(hasValue);

export const store = configureStore({
  reducer: reducers,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(...middleware),
});
