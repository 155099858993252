import { gql } from "@apollo/client";
import {
  Character,
  CharacterImage,
  CharacterProduct,
  Content,
  ContentItem,
  ContentProduct,
  Conversation,
  Message,
  MessageContent,
  MessageSection,
  Product,
  Source,
  User,
} from "./generated/types";

export type BaseUserFields = Pick<
  User,
  "__typename" | "id" | "email" | "name" | "createdAt" | "isSuperuser"
>;

export const BaseUserFields = gql`
  fragment BaseUserFields on User {
    id
    email
    name
    createdAt
    isSuperuser
  }
`;

export type BaseCharacterImageFields = Pick<
  CharacterImage,
  "backgroundColor" | "id" | "imageUrl" | "resizeMode"
>;

export const BaseCharacterImageFields = gql`
  fragment BaseCharacterImageFields on CharacterImage {
    backgroundColor
    id
    imageUrl
    resizeMode
  }
`;

export type BaseCharacterFields = Pick<
  Character,
  | "name"
  | "id"
  | "createdAt"
  | "updatedAt"
  | "description"
  | "images"
  | "defaultQuestions"
  | "thumbnailImageUrl"
  | "personalityPrompt"
> & {
  images: BaseCharacterImageFields[];
};

export const BaseCharacterFields = gql`
  ${BaseCharacterImageFields}
  fragment BaseCharacterFields on Character {
    name
    id
    createdAt
    updatedAt
    description
    thumbnailImageUrl
    personalityPrompt
    images {
      ...BaseCharacterImageFields
    }
    defaultQuestions {
      question
    }
  }
`;

export type BaseConversationFields = Pick<
  Conversation,
  | "__typename"
  | "id"
  | "updatedAt"
  | "createdAt"
  | "characterId"
  | "userId"
  | "startingPrompt"
> & {
  character: BaseCharacterFields | null;
};

export const BaseConversationFields = gql`
  ${BaseCharacterFields}
  fragment BaseConversationFields on Conversation {
    id
    updatedAt
    createdAt
    characterId
    userId
    startingPrompt
    character {
      ...BaseCharacterFields
    }
  }
`;

export type BaseMessageSectionFields = Pick<
  MessageSection,
  "type" | "title" | "body" | "items"
>;

export const BaseMessageSectionFields = gql`
  fragment BaseMessageSectionFields on MessageSection {
    type
    title
    body
    items {
      imageUrl
      url
      caption
      type
      title
      priceCents
      productId
      contentId
      relatedMessageContentId
      urlOpenType
    }
  }
`;

export type BaseCharacterProductFields = Pick<
  CharacterProduct,
  | "id"
  | "imageUrl"
  | "isFavorite"
  | "position"
  | "backgroundColor"
  | "resizeMode"
  | "title"
  | "price"
  | "priceFormatted"
  | "createdAt"
  | "updatedAt"
  | "vendorName"
>;

export const BaseCharacterProductFields = gql`
  fragment BaseCharacterProductFields on CharacterProduct {
    id
    imageUrl
    isFavorite
    position
    backgroundColor
    resizeMode
    title
    price
    priceFormatted
    createdAt
    updatedAt
    vendorName
  }
`;

export type BaseContentFields = Pick<
  Content,
  | "id"
  | "content"
  | "createdAt"
  | "description"
  | "thumbnailImageUrl"
  | "updatedAt"
  | "sourceId"
  | "sourceUrl"
  | "title"
  | "lastEmbeddedAt"
  | "type"
  | "status"
  | "numberOfItems"
  | "numberOfProducts"
> & {
  source: Pick<Source, "id" | "dataSource" | "sourceImageUrl">;
  products: Pick<
    ContentProduct,
    | "content"
    | "shoppingUrl"
    | "gptDescription"
    | "contentDescription"
    | "productDescription"
    | "discount"
    | "currency"
    | "thumbnailImageUrl"
    | "id"
    | "productId"
    | "imageUrl"
    | "title"
    | "vendorName"
    | "priceCents"
    | "keywords"
    | "brandName"
  >[];
  items: Pick<
    ContentItem,
    | "description"
    | "id"
    | "imageUrl"
    | "productIds"
    | "position"
    | "title"
    | "type"
    | "keywords"
    | "gptDescription"
    | "substituteProductIds"
  >[];
};

export const BaseContentFields = gql`
  fragment BaseContentFields on Content {
    id
    type
    content
    createdAt
    description
    thumbnailImageUrl
    updatedAt
    sourceId
    sourceUrl
    lastEmbeddedAt
    status
    numberOfItems
    numberOfProducts
    source {
      id
      dataSource
      sourceImageUrl
    }
    title
    items {
      id
      imageUrl
      description
      productIds
      substituteProductIds
      position
      title
      type
      keywords
      gptDescription
    }
    products {
      id
      content
      shoppingUrl
      thumbnailImageUrl
      productId
      imageUrl
      title
      vendorName
      priceCents
      keywords
      brandName
      gptDescription
      contentDescription
      productDescription
      discount {
        type
        amountCents
        newPriceCents
        percentage
      }
      currency
    }
  }
`;

export type BaseSourceFields = Pick<
  Source,
  | "id"
  | "createdAt"
  | "updatedAt"
  | "url"
  | "description"
  | "dataSource"
  | "sourceImageUrl"
>;

export const BaseSourceFields = gql`
  fragment BaseSourceFields on Source {
    id
    url
    description
    dataSource
    sourceImageUrl
    createdAt
    updatedAt
  }
`;

export type BaseMessageFields = Pick<
  Message,
  | "__typename"
  | "id"
  | "createdAt"
  | "updatedAt"
  | "message"
  | "fromType"
  | "sections"
  | "idempotencyKey"
> & {
  content: MessageContent[];
};

export const BaseMessageFields = gql`
  ${BaseMessageSectionFields}
  fragment BaseMessageFields on Message {
    id
    createdAt
    updatedAt
    message
    fromType
    idempotencyKey
    sections {
      ...BaseMessageSectionFields
    }
    content {
      id
      score
      highlight
      sourceUrl
      imageUrl
      imageDescription
      title
    }
  }
`;

export type BaseProductFields = Pick<
  Product,
  | "backgroundColor"
  | "id"
  | "imageUrl"
  | "description"
  | "priceCents"
  | "title"
  | "shoppingUrl"
  | "vendorName"
  | "brandName"
>;

export const BaseProductFields = gql`
  fragment BaseProductFields on Product {
    id
    imageUrl
    description
    vendorName
    shoppingUrl
    backgroundColor
    title
    priceCents
    brandName
  }
`;
