import { useLazyQuery, useMutation } from "@apollo/client";
import { Button, Divider, Flex, HStack, Text, Tooltip } from "@chakra-ui/react";
import { keyBy } from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useFieldArray, useFormContext } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { show } from "redux-modal";
import { api } from "src/api";
import {
  BaseContentFields,
  BaseProductFields,
  BaseSourceFields,
} from "src/api/fragments";
import {
  ContentItemTypeEnum,
  ContentStatusEnum,
  ContentTypeEnum,
  MutationCreateContentArgs,
  MutationUpdateContentArgs,
  Query,
  QueryDescribeImageArgs,
  QueryScrapeProductsFromMarkupArgs,
} from "src/api/generated/types";
import { ActionSheet } from "src/components/ActionSheet";
import { Input, Option, Select, Textarea } from "src/components/Form";
import { Touchable } from "src/components/Touchable";
import { useMyToast, useTheme } from "src/hooks";
import { getActiveCharacter } from "src/redux/reducers/activeCharacter";
import { colors } from "src/theme";
import { cleanUrl, D } from "src/utils/helpers";
import { v4 as uuidv4 } from "uuid";
import { ContentItemModalProps } from "../ContentItemModal/ContentItemModal";
import {
  FormValues,
  ItemFormValue,
  ProductFormValue,
  TO_ITEM_GQL,
  TO_PRODUCT_GQL,
} from "./form";

type ProductOption = Option & { product: any; value: string };

const TYPE_OPTIONS = [
  {
    value: ContentTypeEnum.Blog,
    label: "Blog 📝",
  },
  {
    value: ContentTypeEnum.Image,
    label: "Image 🖼️",
  },
  {
    value: ContentTypeEnum.SocialMedia,
    label: "Social Media 👯‍♂️",
  },
  {
    value: ContentTypeEnum.Product,
    label: "Product 🛍️",
  },
  {
    value: ContentTypeEnum.Collection,
    label: "Collection 📰",
  },
  {
    value: ContentTypeEnum.Podcast,
    label: "Podcast 🎙️",
  },
  {
    value: ContentTypeEnum.Outfit,
    label: "Outfit 👗",
  },
  {
    value: ContentTypeEnum.Faq,
    label: "FAQ ❓",
  },
];

const STATUS_OPTIONS = [
  {
    value: ContentStatusEnum.Finished,
    label: "Finished ✅",
  },
  {
    value: ContentStatusEnum.InProgress,
    label: "In-progress 🏃‍♂️",
  },
];

const HIDE_CONTENT_FIELDS = new Set([
  ContentTypeEnum.Image,
  ContentTypeEnum.Product,
]);

function ContentForm({
  content,
  sourceId,
  onComplete,
  source,
}: {
  content: BaseContentFields | null;
  sourceId: string;
  source?: BaseSourceFields;
  onComplete: () => void;
}) {
  const contentId = content?.id;
  const character = useSelector(getActiveCharacter);

  const theme = useTheme();
  const toast = useMyToast();
  const [scrapContent] = useLazyQuery<Pick<Query, "scrapeUrl">>(
    api.content.scrape
  );
  const [scrapeProducts] = useLazyQuery<
    Pick<Query, "scrapeProductsFromMarkup">
  >(api.content.scrapeProducts);
  const [embedContent] = useMutation(api.content.embed);
  const [updateContent] = useMutation(api.content.update);
  const [createContent] = useMutation(api.content.create);

  const formProps = useFormContext<FormValues>();
  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { isSubmitting, isDirty },
  } = formProps;

  const _scrapeContent = async () => {
    try {
      const url = getValues().url;

      const response = await scrapContent({
        variables: {
          url,
        },
        fetchPolicy: "network-only",
      });

      const title = response.data?.scrapeUrl?.title || "";
      const content = response.data?.scrapeUrl?.body || "";
      const imageUrls = response.data?.scrapeUrl?.images || [];

      setValue("title", title);
      setValue("content", content);
      setValue(
        "items",
        imageUrls.map((url) => ({
          id: uuidv4(),
          type: ContentItemTypeEnum.Image,
          title: "",
          description: "",
          gptDescription: null,
          imageUrl: url,
          productIds: [],
          substituteProductIds: [],
          keywords: [],
        }))
      );

      toast.show({
        message: "Content scraped!",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: "Error",
        status: "error",
      });
    }
  };

  const _findProducts = async () => {
    try {
      const url = getValues().url;
      const content = getValues().content;

      if (type === ContentTypeEnum.Product || type === ContentTypeEnum.Image) {
        toast.show({
          message: "Cannot find products for this type.",
          status: "error",
        });
        return;
      }

      if (!content) {
        toast.show({
          message: "Content not found. Must save content as markdown first.",
          status: "error",
        });
      }

      const variables: QueryScrapeProductsFromMarkupArgs = {
        url,
        markdown: content,
      };

      const response = await scrapeProducts({
        variables,
        fetchPolicy: "network-only",
      });

      const products = response.data?.scrapeProductsFromMarkup ?? [];

      const formProducts = products.map(
        (p): ProductFormValue => ({
          id: uuidv4(),
          brandName: p.brandName || "",
          productId: p.productId || "",
          title: p.title || "",
          vendorName: p.vendorName || "",
          imageUrl: p.imageUrl || "",
          shoppingUrl: p.shoppingUrl || "",
          contentDescription: p.description || "",
          gptDescription: null,
          productDescription: null,
          discount: null,
          price: D((p.price ?? 0) * 100).toUnit(),
          keywords: [],
          currency: "USD",
        })
      );

      // console.log(products, formProducts);

      const productByImage = keyBy(products, (p) => {
        return cleanUrl(p.imageUrl || "") || uuidv4();
      });

      console.log(productByImage);

      setValue("products", [...getValues().products, ...formProducts]);

      // update the images with the product ids if applicable
      // const items = getValues().items.map((i) => {
      //   const product = productByImage[cleanUrl(i.imageUrl || "")];

      //   const newProductIds = [
      //     ...(i.productIds ?? []),
      //     product?.productId,
      //   ].filter(hasValue);

      //   return {
      //     ...i,
      //     productIds: newProductIds,
      //   };
      // });

      // setValue("items", items);
    } catch (err) {
      toast.show({
        message: "Error",
        status: "error",
      });
    }
  };

  const _embedContent = async () => {
    // embed the content
    if (!content) {
      toast.show({
        message: "Content not found. Must save content first.",
        status: "error",
      });
      return;
    }

    try {
      const response = await embedContent({
        variables: {
          contentId: content.id,
        },
        refetchQueries: [api.content.retrieve],
      });

      toast.show({
        message: "Content embedded",
        status: "success",
      });
    } catch (err) {
      toast.show({
        message: "Error",
        status: "error",
      });
    }
  };

  const onSave = async (values: FormValues) => {
    if (!character) {
      return;
    }

    const isProduct = values.type === ContentTypeEnum.Product;
    const isImage = values.type === ContentTypeEnum.Image;

    const _title = values.title || "";
    const _content = values.content || "";
    const _url = values.url || "";

    const products = (values.products || []).map(TO_PRODUCT_GQL) ?? [];
    const items = (values.items || []).map(TO_ITEM_GQL) ?? [];

    const thumbnailImageUrl =
      (isProduct ? products[0]?.imageUrl : items[0]?.imageUrl) || "";

    const title =
      (isProduct ? products[0]?.title : isImage ? items[0].title : _title) ||
      "";

    const content =
      (isProduct
        ? products[0]?.contentDescription
        : isImage
        ? items[0].description
        : _content) || "";

    const sourceUrl =
      (isProduct
        ? products[0]?.shoppingUrl
        : isImage
        ? items[0].imageUrl
        : _url) || "";

    if (!contentId) {
      if (!sourceId) {
        alert("No source id");
        return;
      }

      // create the content
      const variables: MutationCreateContentArgs = {
        characterId: character.id,
        sourceId: sourceId,
        type: values.type,
        sourceUrl,
        title,
        description: "",
        content: content,
        thumbnailImageUrl,
        items,
        products,
      };

      await createContent({
        variables,
        refetchQueries: [api.characters.content, api.characters.sources],
      });

      onComplete();

      toast.show({
        message: "Content created",
        status: "success",
      });

      return;
    }

    // otherwise just update it
    // create the content
    const variables: MutationUpdateContentArgs = {
      type: values.type,
      contentId: contentId,
      status: values.status,
      sourceUrl,
      title,
      description: "",
      content: content,
      thumbnailImageUrl,
      items,
      products,
    };

    await updateContent({
      variables,
      refetchQueries: [api.characters.content, api.characters.sources],
    });

    onComplete();

    toast.show({
      message: "Content updated.",
      status: "success",
    });

    return;
  };

  const type = formProps.watch("type");
  const status = formProps.watch("status");

  const shouldHide = HIDE_CONTENT_FIELDS.has(type);

  const selectedType = useMemo(
    () => TYPE_OPTIONS.find((o) => o.value === type),
    [type]
  );

  const selectedStatus = useMemo(
    () => STATUS_OPTIONS.find((o) => o.value === status),
    [status]
  );

  return (
    <div
      style={{
        height: "100%",
        flex: 1,
        overflowX: "hidden",
        display: "flex",
        flexDirection: "column",
        position: "relative",
      }}
    >
      <div
        style={{
          flex: 1,
          overflowY: "scroll",
          // height: "100%",
          padding: 10,
          paddingBottom: 100,
        }}
      >
        {source && (
          <div
            style={{
              marginBottom: 20,
              background: theme.secondaryBackground,
              width: "auto",
              padding: 10,
              borderRadius: 500,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <img
              src={source.sourceImageUrl || ""}
              style={{ height: 20, width: 20, borderRadius: 5, marginRight: 5 }}
            />
            <Text style={{ fontSize: 16, color: theme.text }}>
              {source.dataSource} • {source.description}
            </Text>
          </div>
        )}

        <HStack style={{ marginBottom: 5 }}>
          <Select
            options={TYPE_OPTIONS}
            containerStyle={{ flex: 1 }}
            label="Type"
            selectProps={{
              value: selectedType,
              onChange: (o: any) => setValue("type", o.value),
              menuPortalTarget: document.body,
            }}
          />
          <Select
            options={STATUS_OPTIONS}
            label="Status"
            containerStyle={{ flex: 1 }}
            selectProps={{
              value: selectedStatus,
              onChange: (o: any) => setValue("status", o.value),
              menuPortalTarget: document.body,
            }}
          />
        </HStack>

        {!shouldHide && (
          <>
            <HStack
              style={{
                alignItems: "flex-start",
              }}
            >
              <div style={{ flex: 1 }}>
                <Input
                  containerStyle={{ marginBottom: 0 }}
                  name="url"
                  control={control}
                  label="URL"
                />
                <a
                  target="_blank"
                  href={getValues().url || "#"}
                  rel="noreferrer"
                >
                  <Text
                    style={{
                      color: colors.primary,
                      marginTop: 5,
                      fontWeight: "bold",
                    }}
                  >
                    Open url <i className="fas fa-external-link-alt"></i>
                  </Text>
                </a>
              </div>

              <Input
                containerStyle={{
                  flex: 1,
                }}
                label="Title"
                name="title"
                control={control}
              />
            </HStack>

            <br />

            <Textarea
              label="Content"
              textareaProps={{
                minH: 200,
              }}
              name="content"
              control={control}
            />
          </>
        )}

        <MetadataBuilder contentId={contentId || ""} />
      </div>
      <div
        style={{
          position: "absolute",
          bottom: 0,
          zIndex: 100,
          right: 0,
          width: "100%",
          margin: "auto",
          display: "flex",
          border: "1px solid " + theme.border,
          justifyContent: "flex-end",
          borderRadius: 100,
          alignItems: "center",
          padding: 10,
          //blur background
          background: `rgba(255, 255, 255, 0.9)`,
          backdropFilter: "blur(10px)",
        }}
      >
        <Flex
          flex={1}
          style={{
            alignItems: "center",
          }}
        >
          <Tooltip
            bg={colors.black}
            label="Scrapes images, title, and content of the webpage."
          >
            <div>
              <Touchable
                style={{ marginRight: 10 }}
                label="Scrape Content"
                onClick={_scrapeContent}
                iconName="fas fa-spider"
              />
            </div>
          </Tooltip>
          <Tooltip
            bg={colors.black}
            label="Looks through the content field markdown and embeds any products found. Must scrape first and have the content set."
          >
            <div>
              <Touchable
                style={{ marginRight: 10 }}
                label="Find Products"
                onClick={_findProducts}
                iconName="fas fa-user-secret"
              />
            </div>
          </Tooltip>

          <Touchable
            label="Embed Content"
            iconName="fas fa-cogs"
            onClick={_embedContent}
          />

          {content?.lastEmbeddedAt && (
            <Text
              style={{
                marginLeft: 10,
                fontSize: 12,
                color: theme.text,
                fontStyle: "italic",
                background: theme.secondaryBackground,
                padding: "5px 10px",
                borderRadius: 100,
              }}
            >
              Embedded {moment(content.lastEmbeddedAt).format("h:mma, MMM Do")}
            </Text>
          )}
        </Flex>
        <Button
          isLoading={isSubmitting}
          disabled={!isDirty}
          onClick={handleSubmit(onSave, console.error)}
          variant="primary"
        >
          Save
        </Button>
      </div>
    </div>
  );
}

// items and products
const MetadataBuilder = ({ contentId }: { contentId: string }) => {
  // use the form value
  const form = useFormContext<FormValues>();
  const { watch, setValue } = form;
  const dispatch = useDispatch();

  const items = watch("items");
  const products = watch("products");
  const type = watch("type");
  const shouldHideItem = type === ContentTypeEnum.Product;

  const [crawlProducts] = useLazyQuery(api.content.crawlProducts);

  const [activeTab, setActiveTab] = useState(
    shouldHideItem ? "products" : "items"
  );

  useEffect(() => {
    if (shouldHideItem) {
      setActiveTab("products");
    }
  }, [shouldHideItem]);

  const toast = useMyToast();

  // Function to add an image (could be a file picker, for example)
  const addImage = (value: ItemFormValue) => {
    setValue("items", [...items, value]); // Add the new image object to the form state
  };

  const addProduct = (value: BaseProductFields) => {
    console.log(`adding ${value.title}`);
    console.log(value);

    // add it to the products if it isn't already
    const product: ProductFormValue = {
      id: value.id,
      productId: value.id,
      brandName: value.brandName || "",
      imageUrl: value.imageUrl || "",
      title: value.title || "",
      vendorName: value.vendorName || "",
      shoppingUrl: value.shoppingUrl || "",
      contentDescription: value.description || "",
      gptDescription: null,
      productDescription: null,
      currency: "USD",
      discount: null,
      price: D(value.priceCents || 0).toUnit(),
      keywords: [],
    };

    const exists = !!(products || []).find((p) => p.productId === value.id);

    if (exists) {
      return;
    }

    setValue("products", [...products, product]);
  };

  const _addImage = () => {
    dispatch(
      show("ContentItemModal", {
        onSuccess: addImage,
      })
    );
  };

  const _addProduct = () => {
    dispatch(
      show("ProductModal", {
        onSuccess: addProduct,
      })
    );
  };

  const _searchProducts = () => {
    dispatch(
      show("SearchProductsModal", {
        onSuccess: addProduct,
      })
    );
  };

  const _crawlProducts = async () => {
    try {
      await crawlProducts({
        variables: {
          contentId,
        },
      });

      toast.show({
        status: "success",
        message: "Crawling products... Can take a few minutes.",
      });
    } catch (err) {
      toast.show({
        status: "error",
        message: "An error occurred",
      });
    }
  };

  const theme = useTheme();
  const options = useMemo(
    (): ProductOption[] =>
      products.map((p) => ({
        label: p.title || p.contentDescription || p.id,
        value: p.id,
        product: p,
      })),
    [products]
  );

  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <HStack
        style={{
          position: "sticky",
          top: 0,
          marginTop: 40,
          border: `1px solid ${theme.border}`,
          padding: 5,
          zIndex: 100,
          borderRadius: 50,
          // blur background
          backdropFilter: "blur(10px)",
          backgroundColor: `rgba(255, 255, 255, 0.5)`,
        }}
      >
        <Flex flex={1}>
          {!shouldHideItem && (
            <Touchable
              onClick={() => setActiveTab("items")}
              style={{
                borderRadius: 100,
                marginRight: 10,
                padding: "7px 10px",
                background:
                  activeTab === "items" ? colors.primary : "transparent",
              }}
              iconStyle={{
                color: activeTab === "items" ? "white" : undefined,
              }}
              labelStyle={{
                color: activeTab === "items" ? "white" : undefined,
              }}
              label={`Items (${items.length})`}
            />
          )}

          <Touchable
            onClick={() => setActiveTab("products")}
            style={{
              borderRadius: 100,
              padding: "7px 10px",
              background:
                activeTab === "products" ? colors.primary : "transparent",
            }}
            iconStyle={{
              color: activeTab === "products" ? "white" : undefined,
            }}
            labelStyle={{
              color: activeTab === "products" ? "white" : undefined,
            }}
            label={`Products (${products.length})`}
          />
        </Flex>

        {!shouldHideItem && (
          <Touchable
            label="Item"
            iconPosition="right"
            iconName="fas fa-plus-circle"
            onClick={_addImage}
          />
        )}

        <ActionSheet
          content={{
            width: 200,
          }}
          popover={{ placement: "bottom-end" }}
          commands={[
            {
              label: "Existing product",
              iconName: "fas fa-search",
              onClick: _searchProducts,
            },
            {
              label: "Add new product",
              iconName: "fas fa-plus-circle",
              onClick: _addProduct,
            },
          ]}
        >
          <Touchable
            iconPosition="right"
            label="Product"
            iconName="fas fa-plus-circle"
            style={{ marginRight: 5 }}
          />
        </ActionSheet>
      </HStack>

      <div
        style={{
          // horizontally scrollable component
          padding: "20px 0",
          width: "100%",
          display: activeTab === "items" ? "block" : "none",
        }}
      >
        <div style={{}}>
          {items.map((item, index) => (
            <ItemRow
              options={options}
              index={index}
              key={item.id || index}
              item={item}
            />
          ))}
        </div>
      </div>

      <div
        style={{
          // horizontally scrollable component
          padding: "20px 0",
          width: "100%",
          display: activeTab === "products" ? "block" : "none",
        }}
      >
        <div style={{}}>
          <div style={{ textAlign: "right" }}>
            <Button
              variant="primary"
              style={{ marginBottom: 15 }}
              onClick={_crawlProducts}
            >
              Scrape Products Detail URLs{" "}
              <i style={{ marginLeft: 10 }} className="fas fa-spinner" />
            </Button>
          </div>

          {products.map((p, index) => (
            <ProductRow index={index} key={p.id || index} product={p} />
          ))}
        </div>
      </div>
    </div>
  );
};

const ItemRow = ({
  index,
  item,
  options,
}: {
  index: number;
  item: ItemFormValue;
  options: ProductOption[];
}) => {
  // use the form value
  const form = useFormContext<FormValues>();
  const [describeImage] = useLazyQuery<Pick<Query, "describeImage">>(
    api.content.describeImage
  );
  const toast = useMyToast();
  const [largeImage, setLargeImage] = useState(false);

  const { append, remove, update } = useFieldArray({
    control: form.control,
    name: "items",
  });

  const dispatch = useDispatch();

  // Function to add an image (could be a file picker, for example)
  const updateImage = (value: ItemFormValue) => {
    // console.log(value);
    update(index, value);
  };

  // Function to delete an image
  const _deleteImage = (index: number) => {
    // const confirmed = window.confirm("Are you sure you want to delete this?");
    // if (!confirmed) {
    //   return;
    // }
    remove(index); // Remove the selected image
  };

  // Function to edit an image
  const _editImage = () => {
    const props: ContentItemModalProps = {
      item,
      onSuccess: updateImage,
    };

    dispatch(show("ContentItemModal", props));
  };

  const _describe = async () => {
    try {
      if (!item.imageUrl) {
        toast.show({
          message: "No image to describe",
          status: "error",
        });
        return;
      }

      const params: QueryDescribeImageArgs = {
        url: item.imageUrl,
      };

      const descriptionDataResponse = await describeImage({
        variables: params,
      });

      const descriptionData = descriptionDataResponse.data?.describeImage;

      if (descriptionData) {
        const newDescription = item.description
          ? item.description + "\n" + descriptionData.description || ""
          : descriptionData?.description || "";

        const newKeywords = [
          ...(item.keywords ?? []),
          ...descriptionData.keywords,
        ];

        update(index, {
          ...item,
          keywords: newKeywords,
          description: newDescription,
        });
      }
    } catch (err) {
      toast.show({
        status: "error",
        message: "An error occurred",
      });
    }
  };

  // edit product ids
  const _onChangeProductIds = (values: ProductOption[]) => {
    const productIds = values.map((v) => v.value);

    form.setValue(`items.${index}.productIds`, productIds);
  };

  const _onChangeSubstituteProductIds = (values: ProductOption[]) => {
    const productIds = values.map((v) => v.value);

    form.setValue(`items.${index}.substituteProductIds`, productIds);
  };

  const theme = useTheme();

  const productIds = useMemo(() => new Set(item.productIds), [item.productIds]);
  const substituteProductIds = useMemo(
    () => new Set(item.substituteProductIds),
    [item.substituteProductIds]
  );

  const selectedOptions = useMemo(
    () => options.filter((o) => productIds.has(o.value)),
    [item.productIds, options, productIds]
  );

  const selectedSubstituteOptions = useMemo(
    () => options.filter((o) => substituteProductIds.has(o.value)),
    [item.substituteProductIds, options, substituteProductIds]
  );

  return (
    <HStack
      key={index}
      style={{
        padding: "15px 0",
        borderBottom: `1px solid ${theme.border}`,
        width: "100%",
        flexShrink: 0,
      }}
    >
      <div style={{ position: "relative", alignSelf: "flex-start" }}>
        {item.imageUrl ? (
          <img
            onClick={() => setLargeImage(!largeImage)}
            style={{
              borderRadius: 15,
              height: largeImage ? 400 : 100,
              cursor: "pointer",
              width: largeImage ? 300 : 100,
              border: `1px solid ${theme.border}`,
              objectFit: "cover",
              marginRight: 10,
            }}
            src={item.imageUrl} // Using the actual image URL from the form state
          />
        ) : (
          <div
            style={{
              borderRadius: 15,
              height: 50,
              width: 50,
              objectFit: "cover",
              marginRight: 10,
              backgroundColor: theme.secondaryBackground,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <i
              className="fas fa-image"
              style={{ fontSize: 20, color: theme.header }}
            />
          </div>
        )}

        <Tooltip
          label={item.type === ContentItemTypeEnum.Outfit ? "Outfit" : "Image"}
        >
          <div
            style={{
              background: colors.primary,
              position: "absolute",
              borderRadius: 100,
              bottom: -2,
              right: -2,
              height: 25,
              width: 25,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              border: `1px solid ${theme.border}`,
            }}
          >
            <i
              className={
                item.type === ContentItemTypeEnum.Outfit
                  ? "fas fa-tshirt"
                  : "fas fa-image"
              }
              style={{ fontSize: 12, color: colors.white }}
            />
          </div>
        </Tooltip>
      </div>

      <div
        style={{
          fontSize: 12,
          flex: 1,
        }}
      >
        <Text
          noOfLines={4}
          style={{
            // wrap lines
            fontWeight: "normal",
          }}
        >
          {item.title || `Image ${index + 1}`}
        </Text>

        <Tooltip label={item.description || ""}>
          <Text
            noOfLines={2}
            style={{
              marginTop: 5,
              // wrap lines
              fontWeight: "normal",
              color: theme.text,
            }}
          >
            {item.description || "--"}
          </Text>
        </Tooltip>

        {(item.keywords || [])?.length > 0 && (
          <Tooltip label={(item.keywords || []).join(" • ")}>
            <Text
              noOfLines={1}
              style={{
                marginTop: 5,
                // wrap lines
                fontWeight: "normal",
                color: theme.text,
              }}
            >
              Keywords: {(item.keywords || []).join(" • ")}
            </Text>
          </Tooltip>
        )}
      </div>

      <div style={{ width: 275, position: "relative" }}>
        <div>
          <Select
            containerStyle={{ marginBottom: 0, width: "100%" }}
            options={options}
            selectProps={{
              isMulti: true,
              value: selectedOptions,
              isClearable: false,
              placeholder: "Products",
              onChange: ((values: ProductOption[]) =>
                _onChangeProductIds(values)) as any,
            }}
          />
          <Divider style={{ marginTop: 10, marginBottom: 10 }} />
          <div style={{ fontSize: 12, marginBottom: 5 }}>Similar</div>
          <Select
            containerStyle={{ marginBottom: 0, width: "100%" }}
            options={options}
            selectProps={{
              isMulti: true,
              placeholder: "Substitutes",
              value: selectedSubstituteOptions,
              isClearable: false,
              onChange: ((values: ProductOption[]) =>
                _onChangeSubstituteProductIds(values)) as any,
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Touchable
          label="Edit"
          iconName="fas fa-edit"
          labelStyle={{ flex: 1 }}
          onClick={() => _editImage()}
        />
        <Touchable
          label="Describe"
          iconName="fas fa-subtitles"
          labelStyle={{ flex: 1 }}
          onClick={_describe}
        />
        <Touchable
          label="Delete"
          labelStyle={{ flex: 1 }}
          iconName="fas fa-trash"
          onClick={() => _deleteImage(index)}
        />
      </div>
    </HStack>
  );
};

const ProductRow = ({
  index,
  product,
}: {
  index: number;
  product: ProductFormValue;
}) => {
  // use the form value
  const dispatch = useDispatch();
  const [describeImage] = useLazyQuery<Pick<Query, "describeImage">>(
    api.content.describeImage
  );
  const toast = useMyToast();

  const form = useFormContext<FormValues>();

  const { append, remove, update } = useFieldArray({
    control: form.control,
    name: "products",
  });

  // Function to delete an image
  const _delete = (index: number) => {
    const confirmed = window.confirm("Are you sure you want to delete this?");
    if (!confirmed) {
      return;
    }
    remove(index);
  };

  const _editProduct = async (newProduct: ProductFormValue) => {
    update(index, newProduct);
  };

  // Function to edit an image
  const _edit = () => {
    dispatch(
      show("ContentProductModal", {
        product: product,
        onSuccess: _editProduct,
      })
    );
  };

  const _describe = async () => {
    try {
      if (!product.imageUrl) {
        toast.show({
          message: "No image to describe",
          status: "error",
        });
        return;
      }

      const params: QueryDescribeImageArgs = {
        url: product.imageUrl,
      };

      const descriptionDataResponse = await describeImage({
        variables: params,
      });

      const descriptionData = descriptionDataResponse.data?.describeImage;

      // this will only be the GPT description
      if (descriptionData) {
        const newDescription = descriptionData?.description || "";

        const newKeywords = [
          ...(product.keywords ?? []),
          ...descriptionData.keywords,
        ];

        update(index, {
          ...product,
          keywords: newKeywords,
          gptDescription: newDescription,
        });
      }
    } catch (err) {
      toast.show({
        status: "error",
        message: "An error occurred",
      });
    }
  };

  const theme = useTheme();

  return (
    <HStack
      key={index}
      style={{
        padding: "15px 0",
        borderBottom: `1px solid ${theme.border}`,
        width: "100%",
        flexShrink: 0,
      }}
    >
      <img
        style={{
          borderRadius: 15,
          height: 50,
          width: 50,
          objectFit: "cover",
          marginRight: 10,
        }}
        src={product.imageUrl || ""} // Using the actual image URL from the form state
      />

      <div
        style={{
          fontSize: 12,
          flex: 1,
        }}
      >
        <Text
          noOfLines={4}
          style={{
            // wrap lines
            fontWeight: "normal",
          }}
        >
          {product.title || ""}
        </Text>

        <Text
          noOfLines={4}
          style={{
            marginTop: 5,
            // wrap lines
            fontWeight: "normal",
            color: theme.text,
          }}
        >
          {product.vendorName || "No vendor"} •{" "}
          {product.brandName || "No brand"} •{" "}
          {product.contentDescription || "--"} •{" "}
          {D(product.price * 100).toFormat()}
        </Text>

        {(product.keywords || [])?.length > 0 && (
          <Text
            noOfLines={4}
            style={{
              marginTop: 5,
              // wrap lines
              fontWeight: "normal",
              color: theme.text,
            }}
          >
            Keywords: {(product.keywords || []).join(" • ")}
          </Text>
        )}

        <div style={{ marginTop: 10 }}>
          <a
            style={{
              fontWeight: "bold",
              color: colors.primary,
            }}
            href={product.shoppingUrl || ""}
            target="_blank"
            rel="noreferrer"
          >
            <i className="fas fa-external-link-alt" /> Open URL
          </a>
        </div>
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Touchable
          label="Delete"
          iconName="fas fa-trash"
          labelStyle={{ flex: 1 }}
          onClick={() => _delete(index)}
        />
        <Touchable
          label="Edit"
          labelStyle={{ flex: 1 }}
          iconName="fas fa-edit"
          onClick={() => _edit()}
        />
        <Touchable
          label="Describe"
          iconName="fas fa-subtitles"
          onClick={_describe}
        />
      </div>
    </HStack>
  );
};

export default ContentForm;
