import { useQuery } from "@apollo/client";
import { Box } from "@chakra-ui/react";
import { PlusCircle } from "lucide-react";
import { Link } from "react-router-dom";
import { api } from "src/api";
import { Query } from "src/api/generated/types";
import { useTheme } from "src/hooks";

function Characters() {
  const { data } = useQuery<Pick<Query, "getCharacters">>(api.characters.list);

  const characters = data?.getCharacters ?? [];
  const theme = useTheme();

  return (
    <div
      style={{
        flexShrink: 0,
        width: 260,
        backgroundColor: theme.secondaryBackground,
        padding: 0,
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div style={{ margin: "15px 15px" }}>
        <button
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px",
            width: "100%",
            backgroundColor: "white",
            border: "1px solid #e5e5e5",
            borderRadius: "5px",
            cursor: "pointer",
          }}
          onClick={() => alert("v2")}
        >
          <PlusCircle size={20} style={{ marginRight: "10px" }} />
          <span>New character</span>
        </button>
      </div>
      <div style={{ flex: 1, padding: 10, paddingTop: 0 }}>
        {characters.map((item, index) => (
          <Link to={`/dashboard/${item.id}`}>
            <Box
              key={item.id}
              _hover={{
                backgroundColor: "#e5e5e5",
              }}
              style={{
                display: "flex",
                alignItems: "center",
                padding: 10,
                borderRadius: 15,
                cursor: "pointer",
                marginBottom: "10px",
              }}
            >
              <img
                src={item.thumbnailImageUrl || ""}
                style={{
                  width: 30,
                  height: 30,
                  borderRadius: 100,
                  marginRight: 5,
                }}
              />
              <span
                style={{
                  fontWeight: "bold",
                }}
              >
                {item.name}
              </span>
            </Box>
          </Link>
        ))}
      </div>
    </div>
  );
}

export default Characters;
