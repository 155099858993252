import { useMutation } from "@apollo/client";
import { Box, FormLabel, HStack, Spinner, Text } from "@chakra-ui/react";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { useCallback, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useParams } from "react-router-dom";
import { api } from "src/api";
import { Mutation, MutationUploadImageUrlArgs } from "src/api/generated/types";
import { useMyToast } from "src/hooks";
import { useTheme } from "src/hooks/useTheme";
import { colors } from "src/theme";
import { storage } from "src/utils/firebase";
import { v4 as uuidv4 } from "uuid";
import { Button } from "./Button";
import { Input } from "./Form";
import { Touchable } from "./Touchable";

export const ImageUpload = ({
  fileUpload,
  onFileUpload,
}: {
  fileUpload: { name: string; url: string } | null;
  onFileUpload: (p: { name: string; url: string }) => void;
}) => {
  const toast = useMyToast();
  const { characterId } = useParams<{ characterId: string }>();
  const [loading, setLoading] = useState(false);
  const [tempFileUrl, setTempFileUrl] = useState("");

  const [uploadImageUrl] = useMutation<Pick<Mutation, "uploadImageUrl">>(
    api.content.uploadImageUrl
  );

  const onDrop = useCallback(async (acceptedFiles: File[]) => {
    const file = acceptedFiles[0];

    if (!file || !characterId) return;

    try {
      setLoading(true);

      // upload the files to firebase
      const fileRef = ref(
        storage,
        `characters/${characterId}/content/${uuidv4()}-${file.name}`
      );

      await uploadBytes(fileRef, file);

      const url = await getDownloadURL(fileRef);

      // convert the URL if needed
      // if the URL is webp -> convert it to png
      const isWebp = url.includes(".webp");

      if (!isWebp) {
        return onFileUpload({
          name: file.name,
          url,
        });
      }

      // kinda hacky but we just send the image we just uploaded to save it
      const variables: MutationUploadImageUrlArgs = {
        imageUrl: url,
        filePath: `characters/${characterId}/content/${
          file.name
        }-${uuidv4()}.png`,
      };

      const response = await uploadImageUrl({
        variables,
      });

      const imageUrl = response.data?.uploadImageUrl.pngImageUrl;

      if (!imageUrl) {
        toast.show({
          message: "An error occurred.",
          status: "error",
        });
        return;
      }

      onFileUpload({
        name: file.name,
        url: imageUrl,
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    } finally {
      setLoading(false);
    }
  }, []);

  const _uploadTempFile = async () => {
    try {
      // make sure the temp file url is valid image url
      if (!tempFileUrl) {
        toast.show({
          message: "Please enter an image url.",
          status: "error",
        });
        return;
      }

      console.log(tempFileUrl);

      const isValidUrl =
        tempFileUrl.includes(".jpeg") ||
        tempFileUrl.includes(".png") ||
        tempFileUrl.includes(".jpg") ||
        tempFileUrl.includes(".webp");

      if (!isValidUrl) {
        toast.show({
          message: "Please enter a valid image URL",
          status: "error",
        });
        return;
      }

      const variables: MutationUploadImageUrlArgs = {
        imageUrl: tempFileUrl,
        filePath: `characters/${characterId}/content/${uuidv4()}.png`,
      };

      const response = await uploadImageUrl({
        variables,
      });

      const imageUrl = response.data?.uploadImageUrl.pngImageUrl;

      if (!imageUrl) {
        toast.show({
          message: "An error occurred.",
          status: "error",
        });
        return;
      }

      onFileUpload({
        name: "Image",
        url: imageUrl,
      });
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple: false,
    accept: {
      // image files. jpg or png or PDF
      "image/jpeg": [".jpg", ".jpeg"],
      "image/png": [".png"],
      // allow webp as well
      "image/webp": [".webp"],
    },
    onDropRejected: (errors: any[]) => {
      // console.log(err);
      const message = errors
        .map((e) => e.errors.map((e: any) => e.message))
        .join(" ");
      toast.show({
        message,
        status: "error",
      });
    },
  });

  const hasFile = !!fileUpload?.url;
  const theme = useTheme();

  return (
    <>
      <FormLabel
        color={theme.header}
        fontSize={16}
        fontWeight="500"
        marginBottom="6px"
      >
        Image
      </FormLabel>

      <HStack style={{ marginBottom: 15 }}>
        <Input
          placeholder="Image URL"
          value={tempFileUrl}
          onChange={(e) => {
            setTempFileUrl(e.target.value);
          }}
          containerStyle={{ marginBottom: 0, flex: 1 }}
        />
        <Button
          style={{
            minWidth: 50,
          }}
          w="100px"
          variant="primary"
          onClick={_uploadTempFile}
        >
          Upload
        </Button>
      </HStack>

      <div
        style={{
          width: "100%",
          marginBottom: 15,
          textAlign: "center",
          color: theme.text,
        }}
      >
        or drop a file...
      </div>

      {hasFile && (
        <>
          <HStack
            style={{
              marginBottom: "1rem",
              padding: "1rem 1rem",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              cursor: "pointer",
              borderRadius: 5,
              border: `1px solid ` + theme.border,
            }}
            bg={theme.secondaryBackground}
          >
            <img
              src={fileUpload?.url}
              style={{
                width: 40,
                height: 40,
                borderRadius: 10,
                objectFit: "cover",
              }}
            />

            <Text flex={1} color={theme.header} fontSize="sm">
              {loading ? (
                <>
                  Uploading... <Spinner size="xs" />{" "}
                </>
              ) : (
                <>
                  <a
                    style={{
                      color: colors.primary,
                      fontWeight: "bold",
                      fontSize: 16,
                      textDecoration: "underline",
                    }}
                    href={fileUpload?.url}
                    target="_blank"
                    rel="noreferrer"
                  >
                    {fileUpload?.name || "File"}
                  </a>
                </>
              )}
            </Text>

            {/* remove */}
            <Touchable onClick={() => onFileUpload({ name: "", url: "" })}>
              <i
                style={{
                  fontSize: 16,
                  color: colors.red,
                  marginLeft: 15,
                }}
                className="fas fa-times"
              />
            </Touchable>
          </HStack>

          <div
            style={{
              color: theme.text,
              fontSize: 10,
            }}
          >
            URL: {fileUpload.url}
          </div>
        </>
      )}

      {!hasFile && (
        <div
          style={{
            padding: "2rem 1rem",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: 5,
            border: `1px dashed ` + colors.gray80,
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          {isDragActive ? (
            <Text color={theme.header} fontSize="md" fontWeight="semibold">
              Drop the files here ...
            </Text>
          ) : (
            <Box textAlign="center">
              <i
                style={{ color: colors.primary, fontSize: 40 }}
                className="fas fa-camera-viewfinder"
              />
              <Text
                color={theme.header}
                marginTop="1rem"
                fontWeight="bold"
                fontSize="md"
              >
                Drop{" "}
                {/* or <span style={{ color: colors.primary }}>browse</span>{" "} */}
                your file.
              </Text>
            </Box>
          )}
        </div>
      )}

      <br />
    </>
  );
};
