import { useLazyQuery } from "@apollo/client";
import { Button, Container, Flex, HStack } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { connectModal, InjectedProps } from "redux-modal";
import { api } from "src/api";
import {
  ContentItemTypeEnum,
  Query,
  QueryDescribeImageArgs,
} from "src/api/generated/types";
import { Input, Select, Textarea } from "src/components/Form";
import { ImageUpload } from "src/components/ImageUpload";
import { Modal } from "src/components/Modal";
import { Touchable } from "src/components/Touchable";
import { useMyToast } from "src/hooks";
import { v4 as uuidv4 } from "uuid";
import { ItemFormValue } from "../ContentModal/form";

export type ContentItemModalProps = {
  item?: ItemFormValue;
  onSuccess: (i: ItemFormValue) => void;
};

const OPTIONS = [
  {
    label: "Image",
    value: ContentItemTypeEnum.Image,
  },
  {
    label: "Outfit",
    value: ContentItemTypeEnum.Outfit,
  },
];

function _ContentItemModal({
  handleHide,
  item,
  show: isVisible,
  onSuccess,
}: ContentItemModalProps & InjectedProps) {
  const toast = useMyToast();
  const tempImage = item?.imageUrl || "";

  // if it starts with //, replace that with https://
  const _imageUrl = tempImage.startsWith("//")
    ? "https:" + tempImage
    : tempImage;

  const [describeImage] = useLazyQuery<Pick<Query, "describeImage">>(
    api.content.describeImage
  );

  const [imageUrl, setImageUrl] = useState(_imageUrl || "");
  const [description, setDescription] = useState(item?.description || "");
  const [gptDescription, setGptDescription] = useState(
    item?.gptDescription || ""
  );
  const [title, setTitle] = useState(item?.title || "");
  const [type, setType] = useState(item?.type || ContentItemTypeEnum.Image);

  const [keywordSearch, setKeywordSearch] = useState("");
  const [keywords, setKeywords] = useState(item?.keywords || []);

  const setFileUpload = ({ name, url }: { name: string; url: string }) => {
    setImageUrl(url);
    setTitle(name);
  };

  const _onSubmit = async function () {
    try {
      onSuccess({
        id: item?.id || uuidv4(),
        imageUrl: imageUrl,
        type: type || item?.type || ContentItemTypeEnum.Image,
        title,
        description,
        gptDescription,
        productIds: item?.productIds || [],
        substituteProductIds: item?.substituteProductIds || [],
        keywords,
      });

      handleHide();
    } catch (err) {
      toast.show({
        message: (err as Error).message || "An error occurred.",
        status: "error",
      });
    }
  };

  const _describeImage = async () => {
    if (!imageUrl) {
      toast.show({
        message: "Please upload an image",
        status: "error",
      });
      return;
    }

    const params: QueryDescribeImageArgs = {
      url: imageUrl,
    };

    const response = await describeImage({
      variables: params,
    });

    console.log(response);

    const data = response.data?.describeImage;

    if (data) {
      const newDescription = data.description;

      setGptDescription(newDescription || "");
      setKeywords([...keywords, ...(data.keywords || [])]);
    }
  };

  const selectedKeywordOptions = useMemo(
    () =>
      keywords.map((k) => ({
        label: k,
        value: k,
      })),
    [keywords]
  );

  const keywordOptions = useMemo(
    () =>
      [
        ...selectedKeywordOptions,
        {
          label: keywordSearch,
          value: keywordSearch,
        },
      ].filter((k) => !!k.value),
    [selectedKeywordOptions, keywordSearch]
  );

  return (
    <Modal
      title={<>Content Item</>}
      isVisible={isVisible}
      handleHide={handleHide}
      preserveScrollBarGap
      Footer={
        <HStack style={{ width: "100%" }}>
          <Flex flex={1}>
            <Touchable label="Describe Image" onClick={_describeImage} />
          </Flex>
          <Button variant="primary" marginBottom="0" onClick={_onSubmit}>
            Save
          </Button>
        </HStack>
      }
    >
      <Container padding="0px" marginTop="0px !important">
        <ImageUpload
          fileUpload={{
            name: title,
            url: imageUrl,
          }}
          onFileUpload={setFileUpload}
        />

        {/* select type */}
        <Select
          label="Type"
          value={type}
          options={OPTIONS}
          selectProps={{
            value: OPTIONS.find((o) => o.value === type),
            onChange: (o: any) => setType(o.value),
          }}
        />

        <Input
          label="Title"
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />

        <Textarea
          label="Description"
          subtitle="This is what we will use to determine if this image is relevant to a user's question."
          value={description}
          textareaProps={{
            height: 150,
          }}
          onChange={(e) => setDescription(e.target.value)}
        />

        <Textarea
          label="GPT Description"
          subtitle="If there is a GPT generated description."
          value={gptDescription}
          textareaProps={{
            height: 75,
          }}
          onChange={(e) => setGptDescription(e.target.value)}
        />

        <Select
          label="Keywords"
          options={keywordOptions}
          selectProps={{
            isMulti: true,
            onInputChange: (e) => setKeywordSearch(e),
            value: selectedKeywordOptions,

            onChange: (o: any, a) => {
              const newKeywords = [...o.map((k: any) => k.value)];
              setKeywords(newKeywords);
            },
          }}
        />

        <br />
      </Container>
    </Modal>
  );
}

export const ContentItemModal = connectModal({
  name: "ContentItemModal",
})(_ContentItemModal);
