import { combineReducers } from "@reduxjs/toolkit";

// reducers
import { reducer as modalReducer } from "redux-modal";
import { activeCharacterReducer } from "./activeCharacter";
import { chatReducer } from "./chat";
import { globalStateReducer } from "./global";
import { userReducer } from "./user";

const allReducers = {
  user: userReducer,
  global: globalStateReducer,
  activeCharacter: activeCharacterReducer,
  chat: chatReducer,
  modal: modalReducer,
};

export const reducers = combineReducers(allReducers);
