import DineroFactory, { Currency } from "dinero.js";
import { BaseContentFields } from "src/api/fragments";
import { ContentTypeEnum } from "src/api/generated/types";
import { Maybe } from "src/core";

export function D(
  cents: Maybe<number>,
  currency?: Currency
): DineroFactory.Dinero {
  return DineroFactory({
    amount: Math.round(cents || 0),
    currency: currency || "USD",
  });
}

export const getContentTypeIcon = (
  content: Pick<BaseContentFields, "type">
): string => {
  if (content.type === ContentTypeEnum.Blog) {
    return "fas fa-square-pen";
  }

  if (content.type === ContentTypeEnum.Image) {
    return "fas fa-image";
  }

  if (content.type === ContentTypeEnum.Product) {
    return "fas fa-tags";
  }

  if (content.type === ContentTypeEnum.Collection) {
    return "fas fa-folder";
  }

  if (content.type === ContentTypeEnum.Outfit) {
    return "fas fa-tshirt";
  }

  return "fas fa-file-alt";
};

export const cleanUrl = (url: string) => {
  // remove protocol and any query params of a url
  return url
    .replace(/(^\w+:|^)\/\//, "")
    .split("?")[0]
    .toLowerCase();
};
