import { Box, Text } from "@chakra-ui/react";
import React from "react";
import { colors } from "src/theme";

export class ErrorBoundary extends React.Component<any, any> {
  constructor(props: any) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: any, info: any) {
    // Example "componentStack":
    //   in ComponentThatThrows (created by App)
    //   in ErrorBoundary (created by App)
    //   in div (created by App)
    //   in App
    //   logErrorToMyService(error, info.componentStack);
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <ErrorFallback />;
    }

    return this.props.children;
  }
}

const ErrorFallback = () => {
  return (
    <Box h="100vh">
      <Box
        display="flex"
        flexDir={"column"}
        justifyContent="center"
        alignItems="center"
        h="100%"
      >
        <Text
          fontSize="md"
          maxWidth="35rem"
          w="96%"
          textAlign={"center"}
          marginBottom="1rem"
        >
          We are sorry, but it looks like Modiste encountered an issue. All of
          your data is safe. You can{" "}
          <span
            style={{
              fontWeight: "bold",
              color: colors.primary,
              cursor: "pointer",
              textDecoration: "underline",
            }}
            onClick={() => {
              // open email to team@awaken.tax
              window.open("mailto:andrew.j.duca@gmail.com");
              // if ((window as any).Intercom) {
              //   (window as any).Intercom(
              //     "showNewMessage",
              //     "Hello, I was using Awaken but it crashed.\n\nDescribe the issue here:"
              //   );
              // }
            }}
          >
            message customer support
          </span>{" "}
          if you continue having this issue.
          <br />
          <br />
          Thank you for your patience as we resolve this issue 🙏
        </Text>
      </Box>
    </Box>
  );
};
